import { SearchParamsPagination } from '../../types/Filters';
import { Campus, School } from '../../types/Period';
import { TEACHER_PORTAL_SECTIONS } from '../endpoints';
import request from '../request';

export enum SectionStudentSummaryStateEnum {
  Passed = 'PASSED',
  FailedGradeAssistance = 'FAILED_GRADE_ASSISTANCE',
  FailedGrade = 'FAILED_GRADE',
  FailedAssistance = 'FAILED_ASSISTANCE',
}

export interface PeriodTeacherPortal {
  id: number;
  code: string;
  name: string;
  description: string;
  creditMin: number;
  creditMax: number;
  startDate: Date;
  endDate: Date;
  lastLoadDate: Date | null;
  loadingDeadline: Date | null;
  createdAt: Date;
  updatedAt: Date;
  active: boolean;
}

export interface Section {
  id: number;
  name: string;
  maximumStudent: number;
  createdAt: Date;
  updatedAt: Date;
  campusId: number;
  closedAt: Date | null;
  passPercentage: number | null;
  closedWith?: {
    id: number;
    passPercentage: number;
    isSomeStudentPending: boolean;
    createdById: number;
    sectionId: number;
  };
  summaries: Array<{
    id: number;
    passPercentage: number | null;
    createdById: number;
    sectionId: number;
  }>;
  assistanceRequirements: Array<{
    id: number;
    minPercentage: number;
    activityTypeId: number;
    sectionId: number;
    createdAt: Date;
    updatedAt: Date;
    activityType: {
      id: number;
      name: string;
      createdAt: Date;
      updatedAt: Date;
    };
  }>;
  campus: {
    id: number;
    code: string;
    name: string;
    addressStreet: string;
    addressNumber: number;
    addressExtra: string | null;
    communeId: number;
    createdAt: Date;
    updatedAt: Date;
  };
  professors: Array<{
    id: number;
    createdAt: Date;
    updatedAt: Date;
  }>;
  course: {
    id: number;
    name: string;
    code: string;
    shortening: string;
    credits: number;
    createdAt: Date;
    updatedAt: Date;
    school?: School;
  };
  period: {
    id: number;
    code: string;
    name: string;
    description: string;
    creditMin: number;
    creditMax: number;
    startDate: Date;
    endDate: Date;
    lastLoadDate: Date | null;
    loadingDeadline: Date | null;
    createdAt: Date;
    updatedAt: Date;
    active: boolean;
  };
}

export interface Student {
  id: number;
  fullName: string;
  run: string | null;
  passportNumber: string | null;
  countryId: string | null;
  grades: {
    testGrades: Record<
      number,
      {
        studentTestGradeId: number;
        grade: number | string | null;
        testGradeId: number;
      }
    >;
    examGrade: {
      studentFinalGradeId: number;
      grade: number | string | null;
      finalGradeId: number;
    };
    partialGrade: number;
    finalGrade: string | number | null;
    passedGrade: boolean;
  };

  generalPassed: SectionStudentSummaryStateEnum | null;
  assistances: Record<
    number,
    {
      present: number;
      total: number;
      percentage: number | null;
      minPercentage: number;
      passed: number | null;
    }
  >;
  summary: string;
}

export interface GetSectionsTeacherPortalByIDReponse {
  section: Section;
  students: Student[];
  passingGrade: number;
}

export interface SectionTeacherPortal {
  sectionsClosed: Section[];
  sectionsNotClosed: Section[];
}

export interface PeriodsTeacherPortal {
  periods: PeriodTeacherPortal[];
  currentPeriod: PeriodTeacherPortal;
}

export const getFiltersTeacherPortalRequest = () => {
  const url = TEACHER_PORTAL_SECTIONS.FILTER_GET_SECTION;
  return request<{
    periods: PeriodTeacherPortal[];
    currentPeriod: PeriodTeacherPortal;
    schools: School[];
    campus: Campus[];
  }>(url, {
    method: 'get',
  });
};

export const getPeriodsTeacherPortalRequest = () => {
  const url = TEACHER_PORTAL_SECTIONS.GET_PERIODS;
  return request<PeriodsTeacherPortal>(url, {
    method: 'get',
  });
};

export const getSectionTeacherPortalRequest = ({
  page = 0,
  itemsPerPage = 10,
  periodId,
  schoolId,
  campusId,
  searchSection,
  sectionStatus,
}: SearchParamsPagination) => {
  const url = TEACHER_PORTAL_SECTIONS.GET_SECTION;
  const params = {
    items_per_page: itemsPerPage,
    page: page,
    periodId,
    schoolId,
    campusId,
    searchSection,
    sectionStatus,
  };

  return request<SectionTeacherPortal>(url, {
    method: 'get',
    params,
  });
};

export const getSectionsTeacherPortalByIDRequest = (sectionID: number) => {
  const url = TEACHER_PORTAL_SECTIONS.GET_SECTION_BY_ID(sectionID);
  return request<GetSectionsTeacherPortalByIDReponse>(url, {
    method: 'get',
  });
};

export const postClosedSectionTeacherPortalRequest = (sectionId: number) => {
  const url = TEACHER_PORTAL_SECTIONS.POST_CLOSED_SECTION(sectionId);
  return request<any>(url, {
    method: 'post',
  });
};

export const downloadPDFSectionClosed = (sectionId: number) => {
  const url = TEACHER_PORTAL_SECTIONS.DOWNLOAD_SECTION_CLOSED(sectionId);
  return request<any>(url, {
    method: 'get',
    responseType: 'blob',
  });
};
