import { Button, Modal, TextInput, Icon } from "@octano/global-ui";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useValidations } from "../../../hooks/useValidations";

interface Props {
  isOpen: boolean;
  title: string;
  confirmBtnText: string;
  onConfirm: ({ email }: { email: string }) => void;
  onCancel: () => void;
  isLoading?: boolean;
}

export default function RequestEmailModal({
  isOpen,
  title,
  confirmBtnText = "",
  onConfirm = () => null,
  onCancel = () => null,
  isLoading = false,
}: Props) {
  const { t } = useTranslation();
  const { validateEmail, validateTextNotEmpty } = useValidations();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: "",
    },
  });

  return (
    <Modal isOpen={isOpen} toggle={() => null} onClosed={() => reset()}>
      <form
        className="d-flex flex-column align-items-center"
        onSubmit={handleSubmit(onConfirm)}
      >
        <Icon name="mailing" size={40} />
        <h1 className="text-dark fs-20 mb-0 mt-2 text-center">{title}</h1>
        <p className="fs-16 mt-4 mb-3 text-center">
          {t("auth.changePasswordModalTitle")}
        </p>

        <div className="w-100 mt-3 mb-4">
          <TextInput
            label="Email"
            name="email"
            control={control}
            rules={{
              validate: {
                email: validateEmail,
                notEmpty: validateTextNotEmpty,
              },
            }}
          />
        </div>
        <div className="d-flex w-100 flex-wrap" style={{ gap: 22 }}>
          <Button
            text={t(`common.btnCancel`)}
            outlined
            size="lg"
            onClick={onCancel}
            className="flex-grow-1"
            style={{ width: 191 }}
            disabled={isLoading}
          />
          <Button
            text={confirmBtnText}
            size="lg"
            className="flex-grow-1"
            style={{ width: 191 }}
            type="submit"
            loading={isLoading}
          />
        </div>
      </form>
    </Modal>
  );
}
