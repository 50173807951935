import {
  Button,
  CellFormatOptions,
  ColumnTable,
  Table,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import TableEmptyContent from '../../../components/Text/TableEmptyContent';
import { PathsLayouts } from '../../../config/routes';

interface Data {
  id: number;
  acronym: string;
  name: string;
  section: string;
  percentageApproved?: string;
  periodName: string;
  schoolName: string | undefined;
  campusName: string;
}

export interface TableSubjectProps {
  type: 'toClose' | 'closed';
  data: Data[];
  loading: boolean;
  error?: string;
}

const ITEMS_PER_PAGE_DEFAULT = 10;

export default function TableSubject(props: TableSubjectProps) {
  const { t } = useTranslation();
  const { type, error, data, loading } = props;
  const history = useHistory();

  const [currentPage, SetCurrentPage] = useState(1);

  const totalPages = useMemo(() => {
    return Math.ceil(data.length / ITEMS_PER_PAGE_DEFAULT);
  }, [data]);

  const dataSlice = useMemo(() => {
    return data.slice(
      ITEMS_PER_PAGE_DEFAULT * (currentPage - 1),
      ITEMS_PER_PAGE_DEFAULT * currentPage,
    );
  }, [data, currentPage]);

  const columns = useMemo<ColumnTable[]>(() => {
    const array: ColumnTable[] = [
      {
        headerText: t(`common.terms.period`),
        columnName: 'periodName',
        thClassName: 'text-center',
        tdClassName: 'text-center',
      },
      {
        headerText: t(`common.terms.schoolName`),
        columnName: 'schoolName',
        thClassName: 'text-center',
        tdClassName: 'text-center',
      },
      {
        headerText: t(`common.terms.campusName`),
        columnName: 'campusName',
        thClassName: 'text-center',
        tdClassName: 'text-center',
      },
      {
        columnName: 'acronym',
        headerText: t('periodEnd.table.acronym'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        columnName: 'name',
        headerText: t('periodEnd.table.name'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        columnName: 'section',
        headerText: t('periodEnd.table.section'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
    ];

    if (type === 'closed') {
      array.push({
        columnName: 'percentageApproved',
        headerText: t('periodEnd.table.approved'),
        tdClassName: 'text-center',
        thClassName: 'text-center',
      });
    }

    array.push({
      columnName: 'action',
      headerText: t('periodEnd.table.action'),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '200px',
      cellFormat: ({ row }: CellFormatOptions<Data>) => {
        const text =
          type === 'toClose'
            ? t('periodEnd.table.button.reviewClosure')
            : t('periodEnd.table.button.seeClosingAct');
        return (
          <Button
            fullwidth
            text={text}
            size="sm"
            onClick={() => {
              history.push(`${PathsLayouts.periodEnd}/${row.id}`);
            }}
          />
        );
      },
    });

    return array;
  }, [t, type, history]);

  const onChangePage = useCallback(
    (page: number) => {
      SetCurrentPage(page);
    },
    [SetCurrentPage],
  );

  const noResultsText = useMemo(() => {
    if (error) {
      return (
        <TableEmptyContent
          icon="information"
          title={t('periodEnd.table.errorResult.title')}
          subtitle={t('periodEnd.table.errorResult.subtitle')}
        />
      );
    }
    return (
      <TableEmptyContent
        icon="information"
        title={t('periodEnd.table.notResult.title')}
        subtitle={t('periodEnd.table.notResult.subtitle')}
      />
    );
  }, [t, error]);

  useEffect(() => {
    SetCurrentPage(1);
  }, [data]);

  return (
    <>
      <h2 className="text-primary fs-20 fw-700 mt-4 mb-2 text-uppercase">
        {t(`periodEnd.table.subject.${type}`)}
      </h2>
      <Table
        columns={columns}
        data={dataSlice}
        isLoadingResults={loading}
        noResultsText={noResultsText}
        pagination={{
          currentPage,
          itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
          onChangePage,
          totalItems: 1,
          totalPages,
        }}
      />
    </>
  );
}
