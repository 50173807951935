import { Icon } from "@octano/global-ui";

interface TableEmpyContent {
  title: string;
  subtitle: string;
  icon?: "information" | "error";
}

const TableEmptyContent = ({
  title,
  subtitle,
  icon = "information",
}: TableEmpyContent) => {
  return (
    <div className='d-flex align-items-center justify-content-center'>
      <div style={{ maxWidth: 346, marginTop: 70, marginBottom: 65 }}>
        <Icon name={icon} color='primary' size='61px' />
        <h6
          className='text-dark fw-700 mt-4 mb-3 fs-18'
          style={{ opacity: ".6" }}>
          {title}
        </h6>
        <p className='text-light lh-30 mb-0 fs-16'>{subtitle}</p>
      </div>
    </div>
  );
};

export default TableEmptyContent;
