import { Alert, Loading } from '@octano/global-ui';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAttendanceTrackingTable from '../../hooks/useAttendanceTrackingTable';
import { getTableHeadersObj, mapPeriod, mapStudent } from './helpers';
import {
  Header,
  SectionRow,
  SectionsTable,
  TableFilters,
  TrackingTable,
} from './parts';
import { Filters } from './parts/TableFilters';
import { HeadersObj, StudentRow } from './parts/TrackingTable';

const DEFAULT_HEADERS = { months: [], days: [], modules: [] };
const DEFAULT_FILTERS = {
  search: '',
  period: '-1',
};

export default function AttendanceTracking() {
  const { t } = useTranslation();

  const [selectedSection, setSelectedSection] = useState<
    SectionRow | undefined
  >();
  const [filters, setFilters] = useState<Filters>(DEFAULT_FILTERS);
  const [students, setStudents] = useState<StudentRow[]>([]);
  const [headers, setHeaders] = useState<HeadersObj>(DEFAULT_HEADERS);
  const [periods, setPeriods] = useState<{ label: string; value: string }[]>(
    [],
  );

  const {
    table,
    isLoading: isLoadingTable,
    refetch,
  } = useAttendanceTrackingTable({
    sectionId: selectedSection?.id,
  });

  const isClosed = useMemo(
    () => (!isLoadingTable ? !!table?.isClosed : false),
    [isLoadingTable, table?.isClosed],
  );

  useEffect(() => {
    const mappedPeriods = table?.filters?.map(mapPeriod);
    setPeriods(mappedPeriods ?? []);

    const headersObj = getTableHeadersObj(table?.headers ?? []);

    setHeaders(headersObj);
  }, [table]);

  useEffect(() => {
    const mappedStudents = table?.studentsAssistants.map(mapStudent);
    const mappedAndSortedStudents = mappedStudents?.sort((st1, st2) => {
      return st1.fullName > st2.fullName ? 1 : -1;
    });

    setStudents(mappedAndSortedStudents ?? []);
  }, [table]);

  const processedStudents = useMemo(() => {
    let filteredStudents = [];

    if (filters.search.length >= 3) {
      filteredStudents = students.filter((st) =>
        st.fullName.toLowerCase().includes(filters.search.toLowerCase()),
      );
    } else {
      filteredStudents = [...students];
    }

    return filteredStudents;
  }, [students, filters]);

  const sectionAttendance = useMemo(() => {
    const sum = students.reduce((acum, student) => {
      const totalAttendances =
        student.totalClasses - student.totalMissedClasses;
      const totalPercentage = (totalAttendances * 100) / student.totalClasses;

      return totalPercentage + acum;
    }, 0);

    const total = Number(sum / students.length);

    return `${isNaN(total) ? 0 : Math.round(total)}%`;
  }, [students]);

  const resetAllValues = () => {
    setSelectedSection(undefined);
    setFilters(DEFAULT_FILTERS);
    setHeaders(DEFAULT_HEADERS);
    setStudents([]);
    setPeriods([]);
  };

  return (
    <div className="m-3 pt-2 pb-4 px-3 bg-white rounded">
      <>
        {selectedSection !== undefined ? (
          <>
            <Header
              onGoBack={resetAllValues}
              sectionInfo={{
                courseName: selectedSection?.className,
                sectionName: selectedSection?.sectionName,
                sectionAttendance: sectionAttendance,
              }}
            />
            <h2 className="text-primary fs-20 fw-700 mt-3">
              {t(`attendanceTracking.attendanceHistoryTitle`)}
            </h2>
            {!isClosed && (
              <p className="fs-16 mt-2 mb-4">
                {t(`attendanceTracking.attendanceHistoryDescription`)}
              </p>
            )}
            <TableFilters
              periods={periods}
              onFiltersChange={(newValue) =>
                setFilters((filters) => ({ ...filters, ...newValue }))
              }
            />
            {!!isClosed && (
              <Alert
                className="mt-3 mb-2"
                color="info"
                icon="information"
                size="lg"
                text={t(`attendanceTracking.attendanceisClosedAlert`)}
              />
            )}
            {isLoadingTable ? (
              <div
                className="text-center loader"
                style={{ margin: '200px 40%', width: 300 }}
              >
                <Loading textBody={t('common.loading.body')} />
              </div>
            ) : (
              <TrackingTable
                readonly={!!isClosed}
                sectionId={selectedSection?.id}
                courseId={selectedSection?.course?.id}
                headers={headers}
                rows={processedStudents}
                monthIndex={Number(filters.period)}
                onStatusChange={() => refetch(false)}
              />
            )}
          </>
        ) : (
          <>
            <h2 className="text-primary fs-20 fw-700 mt-4">
              {t(`attendanceTracking.sectionSelectTitle`)}
            </h2>
            <p className="fs-16 mt-3 mb-4">
              {t(`attendanceTracking.pageDescription`)}
            </p>
            <SectionsTable
              onSectionSelection={(section) => setSelectedSection(section)}
            />
          </>
        )}
      </>
    </div>
  );
}
