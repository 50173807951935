import { useTranslation } from 'react-i18next';

export interface TotalPercentageFooterProps {
  dataLength: number;
  columnsLength: number;
  error?: boolean;
  percentageValid?: boolean;
  percentage?: string | number;
}

export default function TotalPercentageFooter({
  dataLength,
  columnsLength,
  error,
  percentageValid,
  percentage,
}: TotalPercentageFooterProps) {
  const { t } = useTranslation();
  if (dataLength <= 0) {
    return (
      <tr>
        <td colSpan={columnsLength} className="text-center py-4">
          <span>{t('gradeEntry.partialEvaluationGradeEntry.empty')}</span>
        </td>
      </tr>
    );
  }
  return (
    <tr className="alert-primary text-primary">
      <td
        className="text-primary"
        style={{ height: 'unset', minHeight: 'unset' }}
        colSpan={columnsLength - 1}
      >
        {t('gradeEntry.partialEvaluationGradeEntry.total')}
      </td>
      <td
        className={error && !percentageValid ? 'text-danger' : 'text-primary'}
        style={{ height: 'unset', minHeight: 'unset' }}
      >
        {percentage ?? '-'}%
      </td>
    </tr>
  );
}
