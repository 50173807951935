import {
  CellFormatOptions,
  ColumnTable,
  Table,
  TextOutlinedInput,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

export interface EndCourseGrade {
  name?: string;
  percentage?: string;
}

export interface EndCourseGradeEntryProps {
  onChange: (state: EndCourseGrade[]) => void;
  error?: string | null;
  endCourseGrades: EndCourseGrade[];
  loading: boolean;
}

export default function EndCourseGradeEntry(props: EndCourseGradeEntryProps) {
  const { onChange, error, endCourseGrades, loading } = props;
  const { t } = useTranslation();

  const setEndCourseGradeByWeighting = useCallback(
    (index: number) => {
      return (e: any) => {
        let value = e.target.value;
        if (
          Number(value) <= 100 &&
          Number(value) >= 0 &&
          value.search(/\D/) === -1
        ) {
          endCourseGrades[index].percentage = value;
          onChange([...endCourseGrades]);
        }
      };
    },
    [endCourseGrades, onChange],
  );

  const totalCalculatedPercentage = useMemo(() => {
    let total = 0;
    endCourseGrades.forEach(({ percentage }, key) => {
      if (percentage && endCourseGrades.length - 1 !== key) {
        total += Number(percentage);
      }
    });
    return total;
  }, [endCourseGrades]);

  const columns = useMemo<ColumnTable[]>(
    () => [
      {
        columnName: 'name',
        headerText: t('gradeEntry.endCourseGradeEntry.note'),
        width: 'calc(100% - 120px)',
        thClassName: 'pl-5',
        tdClassName: 'pl-5',
        cellFormat: ({ row }: CellFormatOptions<EndCourseGrade>) => {
          return row.name;
        },
      },
      {
        columnName: 'percentage',
        headerText: t(
          'gradeEntry.partialEvaluationGradeEntry.columns.percentage',
        ),
        width: '120px',
        thClassName: 'text-center',
        tdClassName: 'expander-height-input',
        cellFormat: ({ row, index }: CellFormatOptions<EndCourseGrade>) => {
          if (index === endCourseGrades.length - 1) {
            let colorText =
              totalCalculatedPercentage !== 100 &&
              totalCalculatedPercentage !== 0
                ? 'text-danger'
                : 'text-primary';
            return (
              <p className={`text-center mb-0 ${colorText}`}>{`${
                `${totalCalculatedPercentage}%` || row.percentage
              }`}</p>
            );
          }
          let errorText =
            error && !row.percentage ? t('gradeEntry.require') : undefined;
          let value = row.percentage === '0' ? '' : row.percentage;
          return (
            <TextOutlinedInput
              type="text"
              name="percentage"
              placeholder="—%"
              value={value}
              onChange={setEndCourseGradeByWeighting(index as number)}
              errorText={errorText}
            />
          );
        },
      },
    ],
    [
      t,
      endCourseGrades,
      error,
      setEndCourseGradeByWeighting,
      totalCalculatedPercentage,
    ],
  );

  return (
    <>
      <Row className="mb-4" style={{ height: '42px' }}>
        <Col>
          <h2 className="text-primary fs-20 fw-700 mt-2">
            {t('gradeEntry.endCourseGradeEntry.title')}
          </h2>
        </Col>
      </Row>
      <div className="table-partial-evaluation-grade-entry mb-5">
        <Table
          columns={columns}
          data={endCourseGrades}
          striped={false}
          isLoadingResults={loading}
        />
        {error && <p className="text-danger mt-2">{error}</p>}
      </div>
    </>
  );
}
