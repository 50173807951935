import { Redirect, useRouteMatch } from "react-router";
import { SidenavLayout, ToastProvider } from "@octano/global-ui";
import TopBar from "../components/TopBar/TopBar";
import { CampusFooter } from "../components/CampusFooter/CampusFooter";
import RoutesByLayout from "../components/routes/RoutesByLayout";
import { PathsLayouts } from "../config/routes";
import { useUserState } from "../hooks/useUserState";
import { SIDENAV_LINKS } from "../config/sidenav";

import { IS_ADDEMIC } from "../config/constants";

import AddemicLogoExpanded from "../assets/addemic/logo_expanded.svg";
import AddemicLogo from "../assets/addemic/logo_small.svg";

interface TopBarTitlesDict {
  [route: string]: string;
}

const TITLES_KEY_DICTIONARY: TopBarTitlesDict = {
  "/classes": "classes",
  "/grade-entry": "classes",
  "/reports": "classes",
  "/period-end": "periodEnd",
};

export default function AuthorizedLayout() {
  const routeMatch = useRouteMatch();
  const { isLogged } = useUserState();

  if (!isLogged) {
    return <Redirect to={PathsLayouts.auth} />;
  }

  return (
    <ToastProvider>
      <SidenavLayout
        links={SIDENAV_LINKS}
        logo={IS_ADDEMIC ? ADDEMIC_LOGOS : CAMPUS_LOGOS}
      >
        <div className="d-flex flex-column h-100">
          <TopBar titleKey={TITLES_KEY_DICTIONARY[routeMatch.path]} />
          <div style={{ flexGrow: 1 }}>
            <RoutesByLayout pathLayout={routeMatch.path as PathsLayouts} />
          </div>
          <CampusFooter />
        </div>
      </SidenavLayout>
    </ToastProvider>
  );
}

const CAMPUS_LOGOS = {
  desktop: {
    src: "/tenant/logo_expanded.svg",
    fallbackSrc: "/tenant/logo_expanded.svg",
  },
  mobile: {
    src: "/tenant/logo_small.svg",
    fallbackSrc: "/tenant/logo_small.svg",
  },
};

const ADDEMIC_LOGOS = {
  desktop: {
    src: AddemicLogoExpanded,
    fallbackSrc: AddemicLogoExpanded,
  },
  mobile: {
    src: AddemicLogo,
    fallbackSrc: AddemicLogo,
  },
};
