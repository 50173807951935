import { Button, Modal, TextAreaInput } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import ClassInfoBox, { Props as ClassInfoBoxProps } from "./ClassInfoBox";
import { useValidations } from "../../../hooks/useValidations";
import { useForm } from "react-hook-form";

type FormValues = { justification: string };

interface Props {
  isOpen: boolean;
  classInfo: ClassInfoBoxProps;
  initialMsg?: string;
  onConfirm?: (justification: string) => void;
  onCancel?: () => void;
  onDialogClose?: () => void;
}

export default function JustificationModal({
  isOpen,
  classInfo,
  initialMsg = "",
  onConfirm = () => null,
  onCancel = () => null,
  onDialogClose = () => null,
}: Props) {
  const { t } = useTranslation();

  const { msgValidations } = useValidations();
  const { control, handleSubmit, reset, setValue } = useForm<FormValues>({
    defaultValues: { justification: initialMsg },
  });

  const handleConfirm = ({ justification }: FormValues) => {
    if (justification.length >= 3) {
      onConfirm(justification);
    }
  };

  const handleClose = () => {
    reset();
    onDialogClose();
  };

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={() => null}
      onClosed={handleClose}
      onExit={() => reset()}
      onOpened={() => setValue("justification", initialMsg)}
    >
      <form onSubmit={handleSubmit(handleConfirm)}>
        <p className="text-center text-dark fw-700 fs-22 mb-4">
          {t(`classAttendance.justifyAttendance`)}
        </p>
        <ClassInfoBox {...classInfo} />

        <h5 className="text-medium fs-14 mt-4 mb-1">
          {t(`common.terms.description`)}
        </h5>
        <div style={{ marginTop: -5 }}>
          <TextAreaInput
            maxLength={50}
            label=""
            name="justification"
            rules={{
              required: msgValidations.required,
            }}
            control={control}
          />
        </div>
        <div
          className="d-flex justify-content-between mt-4 mb-2"
          style={{ gap: "1.25rem" }}
        >
          <Button
            fullwidth
            onClick={() => onCancel()}
            text={t(`common.btnCancel`)}
            outlined
          />
          <Button
            fullwidth
            text={t(`common.btnConfirm`)}
            className="mt-0"
            type="submit"
          />
        </div>
      </form>
    </Modal>
  );
}
