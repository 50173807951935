import { useState, useEffect, useMemo } from "react";
import { Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import ChangePasswordForm, {
  FormValues as ChangePasswordFormValues,
} from "./parts/ChangePasswordForm";
import PasswordChangedMsg from "./parts/PasswordChangedMsg";
import InvalidLink from "./parts/InvalidLink";

import {
  checkPasswordChangeToken,
  activateAccount,
  changePassword,
} from "../../api/requests/auth";
import { addToast } from "@octano/global-ui";
import { useTranslation } from "react-i18next";

enum ViewStatus {
  VERIFYING_LINK,
  CHANGING_PASSWORD,
  SUCCEDEED,
  INVALID_LINK,
}

export default function ChangePassword() {
  const { t } = useTranslation();

  const [status, setStatus] = useState<ViewStatus>(ViewStatus.VERIFYING_LINK);
  const [isSavingChanges, setIsSavingChanges] = useState(false);

  const { pathname } = useLocation();
  const type = useMemo<"activate-account" | "reset-password">(
    () => pathname.split("/auth/")[1] as "activate-account" | "reset-password",
    [pathname]
  );

  const queryParams = useQuery();
  const token = useMemo(() => queryParams.get("token") || "", [queryParams]);
  const email = useMemo(() => queryParams.get("email") || "", [queryParams]);

  useEffect(() => {
    const checkToken = async () => {
      const res = await checkPasswordChangeToken({
        type,
        email,
        token,
      });

      if (res.error) setStatus(ViewStatus.INVALID_LINK);
      else setStatus(ViewStatus.CHANGING_PASSWORD);
    };

    if (token && email && type) checkToken();
  }, [token, email, type]);

  const handleAccountActivation = async ({
    newPassword,
    repeatNewPassword,
  }: ChangePasswordFormValues) => {
    setIsSavingChanges(true);
    const res = await activateAccount({
      email,
      token,
      password: newPassword,
      passwordConfirm: repeatNewPassword,
    });
    setIsSavingChanges(false);

    if (res.error) {
      addToast({
        icon: "error",
        color: "danger",
        text: t("auth.changePasswordError"),
      });
    } else {
      setStatus(ViewStatus.SUCCEDEED);
    }
  };

  const handlePasswordChange = async ({
    newPassword,
    repeatNewPassword,
  }: ChangePasswordFormValues) => {
    setIsSavingChanges(true);
    const res = await changePassword({
      email,
      token,
      password: newPassword,
      passwordConfirm: repeatNewPassword,
    });
    setIsSavingChanges(false);

    if (res.error) {
      addToast({
        icon: "error",
        color: "danger",
        text: t("auth.changePasswordError"),
      });
    } else {
      setStatus(ViewStatus.SUCCEDEED);
    }
  };

  return (
    <>
      {status === ViewStatus.VERIFYING_LINK && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner color="primary" size={50} />
        </div>
      )}
      {status === ViewStatus.CHANGING_PASSWORD && (
        <ChangePasswordForm
          isNew={type === "reset-password"}
          isLoading={isSavingChanges}
          userEmail={email}
          onSubmit={
            type === "activate-account"
              ? handleAccountActivation
              : handlePasswordChange
          }
        />
      )}
      {status === ViewStatus.SUCCEDEED && (
        <PasswordChangedMsg isNew={type === "reset-password"} />
      )}
      {status === ViewStatus.INVALID_LINK && <InvalidLink />}
    </>
  );
}
