import { createElement } from "react";
import { Report } from "../../../../types/Reports";
import ClassHistory from "./ClassHistory";

const getActionsByReportType = (reportType: Report["type"]) => {
  const COMPONENTS = {
    LESSONS_TEACHER_UNIQUE: ClassHistory,
  };

  return COMPONENTS[reportType];
};

interface Props {
  report: Report;
}

export default function ReportActionsCell({ report }: Props) {
  const { type, downloadUrl } = report;
  return createElement(getActionsByReportType(type), { downloadUrl });
}
