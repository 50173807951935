import { REPORTS } from "../endpoints";
import request from "../request";
import { ReportsResponse } from "../../types/Reports";

/**
 * Pide la lista de reportes disponibles para descargar
 * @returns
 */
export const getAllReports = () => {
  const url = REPORTS.BASE;
  return request<ReportsResponse>(url);
};

/**
 * Descarga el archivo de reporte del url especificado
 * @param downloadUrl El trozo final del endpoint que identifica de donde se decarga el documnento
 * @returns
 */
export const downloadReport = (downloadUrl: string, queryParams?: any) => {
  const url = REPORTS.DOWNLOAD_BY_URL(downloadUrl);

  return request(url, { params: queryParams, responseType: "blob" });
};

export const uploadReportData = (uploadUrl: string, data: any) => {
  const url = REPORTS.UPLOAD_DATA_BY_URL(uploadUrl);
  return request<{
    statusCode: number;
    message: string;
    rows_with_conflicts?: any[];
  }>(url, { method: "POST", data });
};
