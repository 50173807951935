import AuthorizedLayout from "../layouts/AuthorizedLayout";
import StartClasses from "../views/startClasses/StartClasses";
import ClassAttendance from "../views/classAttendance/ClassAttendance";
import ClassesHistory from "../views/classesHistory/ClassesHistory";
import AttendanceTracking from "../views/AttendanceTracking/AttendanceTracking";
import NotAuthorizedLayout from "../layouts/NotAuthorizedLayout";
import Login from "../views/login/Login";
import ChangePassword from "../views/changePassword/ChangePassword";
import GradeEntryList from "../views/GradeEntry/GradeEntryList";
import CreateEvaluationGradeEntry from "../views/GradeEntry/parts/CreateEvaluationGradeEntry";
import UntrackedAttendance from "../views/untrackedAttendance/UntrackedAttendance";
import AddEvaluationGradeEntry from "../views/GradeEntry/parts/AddEvaluationGradeEntry";
import Reports from "../views/reports/Reports";
import PeriodEnd from "../views/periodEnd/PeriodEnd";
import PeriodEndReview from "../views/periodEndReview/PeriodEndReview";

export enum PathsLayouts {
  auth = "/auth",
  classes = "/classes",
  gradeEntry = "/grade-entry",
  reports = "/reports",
  periodEnd = "/period-end",
}

export type ViewRouteType = {
  path: string;
  component: () => JSX.Element;
};

export type RoutesByLayout = {
  [key in PathsLayouts]: {
    layoutComponent: () => JSX.Element;
    defaultPath: string;
    views: ViewRouteType[];
  };
};

/**
 * Contiene todos los layouts con sus vistas
 */
const routes: RoutesByLayout = {
  [PathsLayouts.auth]: {
    layoutComponent: NotAuthorizedLayout,
    defaultPath: "/",
    views: [
      {
        path: "/",
        component: Login,
      },
      {
        path: "/activate-account",
        component: ChangePassword,
      },
      {
        path: "/reset-password",
        component: ChangePassword,
      },
    ],
  },
  [PathsLayouts.classes]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: "/start",
    views: [
      {
        path: "/start",
        component: StartClasses,
      },
      {
        path: "/:id/attendance",
        component: ClassAttendance,
      },
      {
        path: "/history",
        component: ClassesHistory,
      },
      {
        path: "/untracked-attendance",
        component: UntrackedAttendance,
      },
      {
        path: "/tracking",
        component: AttendanceTracking,
      },
    ],
  },
  [PathsLayouts.gradeEntry]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: "/list",
    views: [
      {
        path: "/list",
        component: GradeEntryList,
      },
      {
        path: "/:id/create-evaluation",
        component: CreateEvaluationGradeEntry,
      },
      {
        path: "/:id/add-evaluation",
        component: AddEvaluationGradeEntry,
      },
    ],
  },
  [PathsLayouts.reports]: {
    defaultPath: "/",
    layoutComponent: AuthorizedLayout,
    views: [
      {
        path: "/",
        component: Reports,
      },
    ],
  },
  [PathsLayouts.periodEnd]: {
    defaultPath: "/",
    layoutComponent: AuthorizedLayout,
    views: [
      {
        path: "/",
        component: PeriodEnd,
      },
      {
        path: "/:id",
        component: PeriodEndReview,
      },
    ],
  },
};

export default routes;
