import { Box, useMobile } from "@octano/global-ui";
import { useTranslation } from "react-i18next";

export interface Props {
  name: string;
  section: string;
}

export default function ClassInfoBox({ name, section }: Props) {
  const isMobile = useMobile();
  const { t } = useTranslation();

  return (
    <Box
      color="primary"
      variant="outlined"
      style={{ borderRadius: 4 }}
      className={`py-2 px-3 ${isMobile && "mt-4 w-100"}`}
      body={
        <div className="d-flex justify-content-between">
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase"
            title={t(`common.terms.course`)}
            style={{ minWidth: isMobile ? 0 : 160 }}
            body={<p className="fs-14 mb-0 text-capitalize">{name}</p>}
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-50 text-uppercase"
            title={t(`common.terms.section`)}
            style={{ minWidth: isMobile ? 0 : 130 }}
            body={<p className="fs-14 mb-0 text-capitalize">{section}</p>}
          />
        </div>
      }
    />
  );
}
