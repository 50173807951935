import { Button, Icon, Modal, IconNameType } from "@octano/global-ui";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

interface ShowDialogProps {
  icon?: IconNameType;
  title?: string;
  subtitle?: string;
  onConfirm?: VoidFunction;
  textBtnConfirm?: string;
  showBtnClose?: boolean;
}

export function showDialogConfirm(
  params: Omit<
    ShowDialogProps,
    "showBtnClose" | "textBtnConfirm" | "showBtnClose"
  >
) {
  const dialogEvent = new CustomEvent("dialogLocal", {
    detail: params,
  });
  document.dispatchEvent(dialogEvent);
}

export function showDialogInfo(
  params: Omit<
    ShowDialogProps,
    "showBtnClose" | "textBtnConfirm" | "showBtnClose"
  >
) {
  const dialogEvent = new CustomEvent("dialogLocal", {
    detail: {
      ...params,
      showBtnClose: false,
      textBtnConfirm: "common.btnGotIt",
    },
  });
  document.dispatchEvent(dialogEvent);
}

export default function Dialog() {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [args, setArgs] = useState<ShowDialogProps>({});

  const {
    icon,
    title,
    subtitle,
    showBtnClose = true,
    textBtnConfirm = `common.btnConfirm`,
    onConfirm: callbackExecute,
  } = args;

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const showDialogConfirm = useCallback(
    (e: any) => {
      setOpen(true);
      setArgs({ ...e.detail });
    },
    [setOpen, setArgs]
  );

  const onConfirm = useCallback(() => {
    close();
    if (callbackExecute) callbackExecute();
  }, [callbackExecute, close]);

  useEffect(() => {
    document.addEventListener("dialogLocal", showDialogConfirm);
    return () => {
      document.removeEventListener("dialogLocal", showDialogConfirm);
    };
  }, [showDialogConfirm]);

  return (
    <Modal isOpen={open} size='md' toggle={close}>
      <header className='text-center mb-4'>
        {icon && <Icon name={icon} size='52px' />}
        {title && (
          <h6
            className='mt-5 fs-22'
            style={{
              color: "#000",
              fontSize: "22px",
            }}>
            {title}
          </h6>
        )}
      </header>
      {subtitle && <p className='fs-18 mb-5 text-center'>{subtitle}</p>}
      <Row>
        {showBtnClose && (
          <Col>
            <Button
              text={t(`common.btnCancel`)}
              outlined
              fullwidth
              onClick={close}
            />
          </Col>
        )}
        <Col>
          <Button
            text={t(textBtnConfirm as string)}
            fullwidth
            onClick={onConfirm}
          />
        </Col>
      </Row>
    </Modal>
  );
}
