import { DetailBox, DetailBoxColumn } from "@octano/global-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "../../../api/requests/teacherPortal";

export interface DetailBoxPeriodEndReviewProps {
  data: Section;
}

export default function DetailBoxPeriodEndReview(
  props: DetailBoxPeriodEndReviewProps
) {
  const { t } = useTranslation();
  const { data } = props;
  const columns = useMemo<DetailBoxColumn[]>(() => {
    return [
      {
        title: t("periodEndReview.detailBox.period"),
        body: data.period.name,
      },
      {
        title: t("periodEndReview.detailBox.acronym"),
        body: data.course.shortening,
      },
      {
        title: t("periodEndReview.detailBox.course"),
        body: data.course.name,
      },
      {
        title: t("periodEndReview.detailBox.section"),
        body: data.name,
      },
      {
        title: t("periodEndReview.detailBox.campus"),
        body: data.campus.name,
      },
    ];
  }, [data, t]);
  return <DetailBox columns={columns} />;
}
