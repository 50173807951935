import { Button, DateInput, Table, useMobile } from '@octano/global-ui';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import RadioCell from './RadioCell';

import { CellFormatOptions } from '@octano/global-ui/dist/components/Table/types/TableTypes';
import dayjs from 'dayjs';
import { getSectionsByAccountId } from '../../api/requests/sections';
import { Section } from '../../types/Section';
import { selectSectionData } from '../../utils/sections';

const SECTIONS_PER_PAGE = 3;

export interface SearchParams {
  sectionId: number;
  initialDate?: string;
  endDate?: string;
}

export enum TimeType {
  FUTURE = 'future',
  PAST = 'past',
}

type DateLimits = { minDate?: Date; maxDate?: Date };

interface Props {
  handleSearch: (params: SearchParams) => void;
  type?: TimeType;
}

export default function ClassSearch({
  handleSearch = () => null,
  type = TimeType.FUTURE,
}: Props) {
  const { t } = useTranslation();
  const [sections, setSections] = useState<Section[]>([]);
  const [sectionsCurrentPage, setSectionsCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoadingSections, setIsLoadingSections] = useState(false);
  const [selectedSection, setSelectedSection] = useState<Section>();
  const [initialDate, setInitialDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const isMobile = useMobile();

  const COLUMNS = [
    {
      columnName: 'acronym',
      headerText: t(`common.terms.shortening`),
      cellFormat: (data: CellFormatOptions<Section>) => (
        <RadioCell
          selected={selectedSection?.sectionName === data.row.sectionName}
          onRadioSelection={() => {
            setSelectedSection({ ...data.row });
          }}
          text={data.value}
          data={data.row}
        />
      ),
    },
    {
      columnName: 'sectionName',
      headerText: t(`common.terms.section`),
    },
    {
      columnName: 'courseName',
      headerText: t(`common.terms.courseName`),
    },
  ];

  const onSearch = () => {
    if (selectedSection) {
      let sDate;
      let eDate;

      if (type === TimeType.FUTURE) {
        sDate = initialDate ? dayjs(initialDate) : dayjs();
        eDate = endDate ? dayjs(endDate) : undefined;
      }

      if (type === TimeType.PAST) {
        eDate = endDate ? dayjs(endDate) : dayjs();
        sDate = initialDate ? dayjs(initialDate) : undefined;
      }

      sDate = sDate?.format('YYYY-MM-DD');
      eDate = eDate?.format('YYYY-MM-DD');

      handleSearch({
        initialDate: sDate,
        endDate: eDate,
        sectionId: selectedSection.id,
      });
    }
  };

  useEffect(() => {
    const getTeacherSections = async () => {
      setIsLoadingSections(true);

      const res = await getSectionsByAccountId({
        itemsPerPage: SECTIONS_PER_PAGE,
        page: sectionsCurrentPage,
      });
      const sections = res.data?.data.map(selectSectionData);
      setSections(sections ?? []);
      setTotalItems(res.data?.total ?? 0);
      setTotalPages(res.data?.total_pages ?? 0);

      setIsLoadingSections(false);
    };

    getTeacherSections();
  }, [sectionsCurrentPage]);

  const startDateLimits: DateLimits = useMemo(() => {
    if (type === TimeType.PAST) {
      return {
        maxDate: dayjs(endDate).toDate(),
      };
    }

    if (type === TimeType.FUTURE) {
      return {
        minDate: new Date(),
      };
    }

    return {};
  }, [endDate, type]);

  const endDateLimits: DateLimits = useMemo(() => {
    if (type === TimeType.PAST) {
      return {
        maxDate: new Date(),
        minDate: dayjs(initialDate).toDate(),
      };
    }

    if (type === TimeType.FUTURE) {
      return {
        minDate: initialDate ? dayjs(initialDate).toDate() : new Date(),
      };
    }

    return {};
  }, [initialDate, type]);

  return (
    <>
      <Row>
        <Col sm="6">
          <h2 className="text-primary fw-700 fs-20 mb-3">
            SELECCIONA UNA SECCIÓN
          </h2>
          <Table
            columns={COLUMNS}
            data={sections}
            pagination={
              totalPages > 1
                ? {
                    totalItems,
                    totalPages,
                    currentPage: sectionsCurrentPage + 1,
                    itemsPerPage: SECTIONS_PER_PAGE,
                    onChangePage: (page) => setSectionsCurrentPage(page - 1),
                  }
                : undefined
            }
            isLoadingResults={isLoadingSections}
            loadingView={{
              title: 'Espera un momento',
              subtitle: 'Los resultados se están cargando.',
            }}
            striped
          />
        </Col>
        <Col sm="6">
          <h2 className="text-primary fw-700 fs-20 mb-3">FILTRA POR FECHA</h2>
          <DateInput
            minDate={startDateLimits?.minDate}
            maxDate={startDateLimits?.maxDate}
            label="FECHA DE INICIO"
            name="initialDate"
            onChange={(date) =>
              setInitialDate(date ? new Date(date) : undefined)
            }
            value={initialDate}
          />
          <DateInput
            minDate={endDateLimits?.minDate}
            maxDate={endDateLimits?.maxDate}
            label="FECHA DE TERMINO"
            name="initialDate"
            onChange={(date) => setEndDate(date ? new Date(date) : undefined)}
            value={endDate}
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <Button
          className={`mt-5 ${isMobile ? 'w-100' : 'w-25'}`}
          color="primary"
          text="BUSCAR"
          onClick={onSearch}
          disabled={!selectedSection}
        />
      </div>
    </>
  );
}
