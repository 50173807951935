import { Box, Button, DateInput, Modal, Select } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';

import useServerSettings from '../../../hooks/useServerSettings';
import useTimeModules from '../../../hooks/useTimeModules';
import { useValidations } from '../../../hooks/useValidations';
import { TimeModule } from '../../../types/TimeModule';

type TimeModuleOption = { label: string; value: string };

interface FormData {
  date: string;
  initModule: TimeModuleOption | null;
  endModule: TimeModuleOption | null;
}

export type SubmitData = Omit<FormData, 'initModule' | 'endModule'> & {
  courseName: string;
  initModule: TimeModule;
  endModule: TimeModule;
};

const DEFAULT_VALUES: FormData = {
  date: '',
  initModule: null,
  endModule: null,
};

interface Props {
  courseName: string;
  isOpen: boolean;
  onConfirm?: (classData: SubmitData) => void;
  onCancel?: () => void;
  onDialogClose?: () => void;
}

export default function ClassRecoveryModal({
  courseName = '',
  isOpen,
  onConfirm = () => null,
  onCancel = () => null,
  onDialogClose = () => null,
}: Props) {
  const { t } = useTranslation();

  const { timeModules } = useTimeModules();
  const { offset: offsetUTC } = useServerSettings();
  const { control, reset, setValue, watch, getValues, handleSubmit } =
    useForm<FormData>({ defaultValues: DEFAULT_VALUES });
  const { msgValidations } = useValidations();
  const watchDate = watch('date');
  const watchStartModule = watch('initModule');

  const handleDialogClose = () => {
    reset();
    onDialogClose();
  };

  const handleFormSubmit = async (formData: FormData) => {
    onConfirm({
      ...formData,
      courseName,
      initModule: JSON.parse(formData.initModule?.value ?? ''),
      endModule: JSON.parse(formData.endModule?.value ?? ''),
    });
  };

  const moduleOptions: TimeModuleOption[] = useMemo(() => {
    let options = [...timeModules];
    const todaysDateTime = dayjs()
      .utc()
      .utcOffset(offsetUTC || 0)
      .second(0);

    // Si la fecha es la de hoy
    if (watchDate && dayjs(watchDate).isSame(todaysDateTime, 'day')) {
      options = options.filter((timeModule) => {
        const [hours, minutes] = timeModule.endTime
          .split(':')
          .map((n) => Number(n));

        const classDateTime = dayjs()
          .utc()
          .utcOffset(offsetUTC)
          .hour(hours)
          .minute(minutes)
          .second(0);

        return (
          classDateTime.isAfter(todaysDateTime, 'h') &&
          classDateTime.isAfter(todaysDateTime, 'm')
        );
      });
    }

    return options.map((tm: TimeModule) => ({
      label: `${tm.shortening} - ${tm.name}`,
      value: JSON.stringify(tm),
    }));
  }, [watchDate, timeModules, offsetUTC]);

  const findModuleIndex = (
    modulesList: TimeModuleOption[],
    moduleId: number,
  ) => {
    const minIndex = modulesList.findIndex(
      (mod) => JSON.parse(mod.value)?.id === moduleId,
    );

    return minIndex;
  };

  // El modulo de fin tiene como minimo el inicial
  const endModuleOptions = useMemo(() => {
    if (!watchStartModule) return moduleOptions;

    const id = JSON.parse(watchStartModule.value)?.id;
    const minIndex = findModuleIndex(moduleOptions, id);

    return moduleOptions.slice(minIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchStartModule, moduleOptions]);

  // Los modulos nunca pueden cruzarse
  useEffect(() => {
    if (!watchStartModule) return;

    const endModule = getValues('endModule');

    if (endModule) {
      const startModuleId = JSON.parse(watchStartModule.value).id;
      const endModuleId = JSON.parse(endModule.value).id;

      const startModuleIndex = findModuleIndex(moduleOptions, startModuleId);
      const endModuleIndex = findModuleIndex(moduleOptions, endModuleId);

      if (startModuleIndex > endModuleIndex) {
        setValue('endModule', watchStartModule);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchStartModule, moduleOptions]);

  // Si la fecha no tiene disponible alguno de los modulos seleccionados, se deseleccionan
  useEffect(() => {
    if (!watchDate) return;

    const { initModule } = getValues();
    const inOptions = (module: TimeModuleOption) =>
      moduleOptions.find((opt) => opt.value === module?.value);

    if (initModule && !inOptions(initModule)) {
      setValue('initModule', null);
      setValue('endModule', null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDate]);

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={() => null}
      onClosed={handleDialogClose}
    >
      <p className="text-center text-dark fw-700 fs-22 mb-5">
        {t(`classesHistory.recoverClass`)}
      </p>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Container>
          <Row className="pb-sm-3 pb-3 pb-lg-0 pb-md-0">
            <Col
              lg="6"
              md="6"
              sm="12"
              className="px-0 pr-lg-3 pr-md-3 pb-3 pr-sm-0 pr-xs-0"
            >
              <Box
                color="primary"
                variant="outlined"
                title={t('common.terms.course')}
                body={courseName}
                style={{ padding: '8px 15px', minWidth: '100%' }}
              />
            </Col>
            <Col lg="6" md="6" sm="12" className="px-0">
              <DateInput
                minDate={new Date()}
                label={t('common.terms.date')}
                name="date"
                control={control}
                rules={{
                  required: msgValidations.required,
                }}
              />
            </Col>
          </Row>
          <Row className="pb-sm-3 pb-xs-3 pb-lg-0 pb-md-0">
            <Col
              lg="6"
              md="6"
              sm="12"
              className="px-0 pr-lg-3 pr-md-3 pb-3 pr-sm-0 pr-xs-0"
            >
              <Select
                label={t('classesHistory.startModule')}
                name="initModule"
                options={moduleOptions}
                control={control}
                rules={{
                  required: msgValidations.required,
                }}
              />
            </Col>
            <Col lg="6" md="6" sm="12" className="px-0">
              <Select
                label={t('classesHistory.endModule')}
                name="endModule"
                options={endModuleOptions}
                control={control}
                rules={{
                  required: msgValidations.required,
                }}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col
              lg="6"
              md="6"
              sm="12"
              className="px-0 pr-lg-3 pr-md-3 pr-sm-0 pr-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3"
            >
              <Button
                fullwidth
                onClick={() => onCancel()}
                text={t(`common.btnCancel`)}
                outlined
                className="mr-3"
              />
            </Col>
            <Col lg="6" md="6" sm="12" className="px-0">
              <Button
                fullwidth
                text={t(`common.btnConfirm`)}
                className="mt-0"
                type="submit"
              />
            </Col>
          </Row>
        </Container>
      </form>
    </Modal>
  );
}
