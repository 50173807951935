import { GRADE_ENTRY } from '../../../api/endpoints';
import request from '../../../api/request';
import {
  GradeEntry,
  TeachaerSectionCourseOfGradeEntry,
} from '../../../api/requests/gradeEntry';
import { GradesConfig } from '../types';

export const getSectionGradeConfig = (id: number | string) => {
  const url = GRADE_ENTRY.SECTION_GRADE_CONFIG(id);
  return request<{
    sectionData: TeachaerSectionCourseOfGradeEntry;
    gradesConfig: GradeEntry;
  }>(url, {
    method: 'get',
  });
};

export const createEvaluationOfGradeEntry = (
  id: number | string,
  data: GradesConfig,
) => {
  const url = GRADE_ENTRY.SECTION_GRADE_CONFIG(id);
  return request<GradesConfig>(url, {
    method: 'post',
    data,
  });
};
