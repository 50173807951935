import { useState, useEffect, useCallback } from "react";
import {
  AttendanceTableResponse,
  Assistant,
  EmptyAssistant,
} from "../types/AttendanceTracking";
import { getTrackingTable } from "../api/requests/attendance";

type Params = { sectionId?: number };

export default function useAttendanceTrackingTable({ sectionId = 0 }: Params) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>();
  const [table, setTable] = useState<AttendanceTableResponse>();

  const fetchTable = useCallback(
    async (useLoader: boolean = true) => {
      if (sectionId === 0) return;

      setError(false);

      if (useLoader) setIsLoading(true);

      const res = await getTrackingTable({ sectionId });

      if (res.error) {
        setError(true);
      } else {
        const studentsAssistants = res.data?.data.studentsAssistants.map(
          (student) => ({
            ...student,
            assistants: getReorderedAttendance(
              res.data?.data.headers,
              student.assistants as Assistant[]
            ),
          })
        );

        setTable({ ...res.data?.data, studentsAssistants });
      }

      setIsLoading(false);
    },
    [sectionId]
  );

  const refetch = async (useLoader?: boolean) => {
    await fetchTable(useLoader);
  };

  useEffect(() => {
    fetchTable();
  }, [sectionId, fetchTable]);

  return {
    isLoading,
    error,
    table,
    refetch,
  };
}

interface AttendanceByDateDict {
  [index: string]: Assistant[];
}

function getReorderedAttendance(
  headers: AttendanceTableResponse["headers"],
  studentAttendance: Assistant[]
): (Assistant | EmptyAssistant)[] {
  studentAttendance.sort((at1, at2) =>
    new Date(at1.date) > new Date(at2.date) ? 1 : -1
  );

  const datesDict: AttendanceByDateDict = studentAttendance.reduce(
    (dict, attendance) => {
      const date = attendance.date;

      if (!(dict as AttendanceByDateDict)[date]) {
        (dict as AttendanceByDateDict)[date] = [];
      }

      (dict as AttendanceByDateDict)[date].push(attendance);
      return dict;
    },
    {}
  );

  const orderedStudentAttendances = headers
    .map((header) => {
      const hModules = header.modules;
      const finalAttendances: (Assistant | EmptyAssistant)[] = [];

      hModules.forEach((hModule) => {
        const matchedAttendance = datesDict[header.date]?.find(
          (at) => at.module.id === hModule.id
        );

        if (matchedAttendance) {
          finalAttendances.push(matchedAttendance);
        } else {
          finalAttendances.push({
            date: header.date,
            monthNumber: header.monthNumber,
            month: header.month,
            day: header.day,
            status: null,
            module: hModule,
          });
        }
      });

      return finalAttendances;
    })
    .flat();

  return orderedStudentAttendances;
}
