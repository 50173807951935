import { useTranslation } from "react-i18next";
import { Button, Icon } from "@octano/global-ui";
import { AttendanceStatus } from "../../../types/Class";
import { UncontrolledTooltip } from "reactstrap";
/* 
  Se debe incluir un id si se van a utilizar multiples instancias del status toggler con 
  icono de informacion. El tooltip de reactstrap utiliza un id para el elemento que añade al DOM
*/
interface Props {
  id?: number;
  value: AttendanceStatus;
  onChange: (newStatus: AttendanceStatus) => void;
  excludeStatuses?: AttendanceStatus[];
  info?: string;
  isLoading?: boolean;
}

export default function StatusToggler({
  id = -1,
  value,
  onChange = () => null,
  excludeStatuses = [],
  info,
  isLoading = false,
}: Props) {
  const { t } = useTranslation();

  const btnsList = Object.values(AttendanceStatus).filter(
    (status) => !excludeStatuses?.find((excSt) => status === excSt)
  );
  const showInformationIcon =
    value === AttendanceStatus.JUSTIFIED &&
    !excludeStatuses.includes(AttendanceStatus.JUSTIFIED);

  return (
    <div className="status-toggler">
      {btnsList.map((status) => (
        <Button
          key={status}
          outlined
          size="sm"
          loading={isLoading}
          text={t(`classAttendance.${status}`)}
          className={`text-uppercase status-btn ${status} ${
            status === value && "selected"
          }`}
          onClick={() => onChange(status)}
        />
      ))}
      <div style={{ minWidth: 16, margin: "0 9px" }}>
        {showInformationIcon && (
          <>
            <div id={`justificationInfoIcon-${id}`}>
              <Icon name="information" color="primary" size={16} />
            </div>
            <UncontrolledTooltip
              placement="bottom"
              target={`justificationInfoIcon-${id}`}
            >
              {info}
            </UncontrolledTooltip>
          </>
        )}
      </div>
    </div>
  );
}
