import { Box, Button, Icon, Modal } from "@octano/global-ui";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  pendingStudents?: string[];
  onConfirm?: () => void;
  onCancel?: () => void;
  onDialogClose?: () => void;
}

export default function CloseConfirmationModal({
  isOpen,
  pendingStudents = [],
  onConfirm = () => null,
  onCancel = () => null,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal size="md" isOpen={isOpen} toggle={() => null}>
      <div className="text-center">
        <Icon name="warning" size="52px" />
        <p
          className="mt-5 mb-2"
          style={{
            color: "#000",
            fontSize: "22px",
          }}
        >
          {t(`classAttendance.closeConfirmation.description`)}
        </p>
        <p className="fs-18 mb-5">
          {t(`classAttendance.closeConfirmation.question`)}
        </p>

        <h5 className="fs-12 text-medium text-left mb-1">
          {t(
            `classAttendance.closeConfirmation.unregisteredStudentsAttendancesTitle`
          )}
        </h5>
        <Box
          fullwidth
          color="primary"
          body={
            <ul className="px-3 text-left text-dark fs-14 py-0">
              {pendingStudents.map((name) => (
                <li>{name}</li>
              ))}
            </ul>
          }
          style={{ height: 145, padding: "0.75rem 1rem", overflow: "auto" }}
        />
        <div
          className="d-flex justify-content-between mt-5 mb-3"
          style={{ gap: "1.25rem" }}
        >
          <Button
            fullwidth
            onClick={() => onCancel()}
            text={t(`common.btnCancel`)}
            outlined
          />
          <Button
            fullwidth
            onClick={() => onConfirm()}
            text={t(`common.btnConfirm`)}
            className="mt-0"
          />
        </div>
      </div>
    </Modal>
  );
}
