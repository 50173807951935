import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Icon } from '@octano/global-ui';
import StatusBox from './StatusBox';
import useClickedOutside from '../../../hooks/useClickedOutside';
import { AttendanceStatus } from '../../../types/Class';

const OPTIONS = Object.values(AttendanceStatus);

interface Props {
  readonly?: boolean;
  status: AttendanceStatus;
  onChange?: (newStatus: AttendanceStatus) => void;
}

export default function StatusSelector({
  readonly = false,
  status,
  onChange = () => null,
}: Props) {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useClickedOutside(containerRef.current as HTMLElement, () =>
    setActive(false),
  );

  const availableOptions = OPTIONS.filter((opt) => opt !== status);

  return (
    <div
      className={clsx(
        'attendance-tracking-status-selector',
        active && 'active',
        !readonly && 'cursor-pointer',
      )}
      ref={containerRef}
      onClick={() => setActive((curr) => !curr)}
    >
      <StatusBox status={status} />
      {!readonly && (
        <>
          <div className="icon-container">
            <Icon name="chevron_down" />
          </div>
          <div className="options-container">
            {availableOptions.map((opt, idx) => (
              <div
                className="option p-2 "
                key={`${opt}-${idx}`}
                onClick={() => onChange(opt)}
              >
                <StatusBox status={opt} size={18} />
                <p className="mb-0 ml-2 text-medium">
                  {t(`classAttendance.${opt}`)}
                </p>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
