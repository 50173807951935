import { Button, Modal, Icon } from "@octano/global-ui";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  description: string;
  onConfirm: () => void;
}

export default function EmailSentModal({
  isOpen,
  description,
  onConfirm = () => null,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column align-items-center">
        <Icon name="success" size={50} />
        <h1 className="text-dark fs-22 mb-0 mt-4 text-center">
          {t("auth.passwordChangeEmailSentTitle")}
        </h1>
        <p className="fs-18 mt-4 mb-3 text-center" style={{ maxWidth: 330 }}>
          {description}
        </p>
        <Button
          text={t("common.btnGotIt")}
          className="mt-4 w-75 text-uppercase"
          style={{ maxWidth: 290 }}
          onClick={() => onConfirm()}
        />
      </div>
    </Modal>
  );
}
