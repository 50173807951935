import { useState, useEffect, useCallback } from 'react';
import { getNearestClasses } from '../api/requests/classes';
import { getServerTimezone } from '../api/requests/settings';
import { Class } from '../types/Class';
import { selectClassData } from '../utils/classes';
import useTimeout from './useTimeout';
import { formatServerTime, getTimeDiff } from '../utils/time';

/**
 * Hook que solicita las clases mas cercanas (del dia) al backend y se actualiza cada vez que la clase mas cercana culmina
 * @returns nearestClasses: arreglo de clases, error: si hubo un error en la ultima peticion, isLoading: si esta realizando la peticion
 */
export default function useNearestClasses({
  accountId,
}: {
  accountId: number | undefined;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [nearestClasses, setNearestClasses] = useState<Class[]>([]);
  const [timeToRefresh, setTimeToRefresh] = useState(0);

  async function requestNearestClasses() {
    if (!accountId) {
      return;
    }

    setIsLoading(true);
    const res = await getNearestClasses(accountId);
    if (res.error) {
      setError(true);
    } else {
      const classes = res.data?.data.map((c) => selectClassData(c)) as Class[];
      setNearestClasses(classes);
    }
    setIsLoading(false);
  }

  const calculateTimeToRefresh = useCallback(
    async function () {
      if (!nearestClasses.length) {
        setTimeToRefresh(0);
        return;
      }

      const [nextClass] = nearestClasses;
      const [, nextClassEndTime] = nextClass.schedule.split(' - ');

      const tzRes = await getServerTimezone();
      const serverTime = formatServerTime(
        tzRes.data?.dateLocalOffset || '00:00',
      );

      const remainingTime = getTimeDiff(nextClassEndTime, serverTime);
      setTimeToRefresh(remainingTime);
    },
    [nearestClasses],
  );

  useTimeout(() => {
    requestNearestClasses();
  }, timeToRefresh);

  useEffect(() => {
    calculateTimeToRefresh();
  }, [nearestClasses, calculateTimeToRefresh]);

  return {
    nearestClasses,
    isLoading,
    error,
  };
}
