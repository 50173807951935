import { TestGrades } from '../../../api/requests/gradeEntry';

function sortEvaluationByName(a: TestGrades, b: TestGrades) {
  const hasGroupA = a?.groupId !== null;
  const hasGroupB = b?.groupId !== null;

  if (hasGroupA && hasGroupB) {
    return (a?.group?.name ?? '').localeCompare(b?.group?.name ?? '');
  } else if (hasGroupA) {
    return (a?.group?.name ?? '').localeCompare(b.name);
  }
  if (hasGroupB) {
    return a.name.localeCompare(b?.group?.name ?? '');
  }

  return a.name.localeCompare(b.name);
}

const sortByEvaluationName = (objects: TestGrades[] = []) =>
  objects.slice().sort(sortEvaluationByName);

export default sortByEvaluationName;
