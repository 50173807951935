import { useTranslation } from "react-i18next";
import UntrackedInitiatedClassesTable from "./parts/UntrackedInitiatedClassesTable";

export default function UntrackedAttendance() {
  const { t } = useTranslation();

  return (
    <div className="m-3 pt-2 pb-4 px-3 bg-white rounded">
      <h2 className="text-primary fs-20 fw-700 mt-4">
        {t(`attendanceTracking.classesWithUntrackedAttendanceTitle`)}
      </h2>
      <p className="fs-16 mt-3 mb-4">
        {t(`attendanceTracking.classesWithUntrackedAttendanceDescription`)}
      </p>
      <UntrackedInitiatedClassesTable />
    </div>
  );
}
