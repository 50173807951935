import { useState } from "react";
import { SearchBox, OutlinedSelect } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import StatusBox from "./StatusBox";
import { AttendanceStatus } from "../../../types/Class";

export type Filters = { search: string; period: string };

interface Props {
  periods?: { label: string; value: string }[];
  onFiltersChange?: (filters: Partial<Filters>) => void;
}

export default function TableFilters({
  periods = [],
  onFiltersChange = () => null,
}: Props) {
  const { t } = useTranslation();

  const DEFAULT_PERIOD = {
    label: t(`attendanceTracking.allPeriods`),
    value: "-1",
  };

  const [search, setSearch] = useState("");
  const [period, setPeriod] = useState(DEFAULT_PERIOD);

  const handleSearchChange = (newSearch: string) => {
    setSearch(newSearch);
    onFiltersChange({ search: newSearch });
  };

  const handlePeriodChange = (newPeriod: { label: string; value: string }) => {
    setPeriod(newPeriod);
    onFiltersChange({ period: newPeriod.value });
  };

  return (
    <div className="d-flex align-items-center">
      <div className="attendance-tracking-search-container mr-4">
        <SearchBox
          onChange={(ev) => {
            handleSearchChange(ev.target.value);
          }}
          value={search}
          name="search"
          placeholder={t(`attendanceTracking.studentSearchPlaceholder`)}
        />
      </div>
      <div
        className="attendance-tracking-period-filter-container mr-5"
        style={{ zIndex: 4 }}
      >
        <OutlinedSelect
          name="period"
          options={[DEFAULT_PERIOD, ...periods]}
          value={period}
          onChange={handlePeriodChange}
          isClearable={false}
          isSearchable={false}
        />
      </div>
      <div className="d-flex align-items-center mr-3">
        <StatusBox status={AttendanceStatus.PRESENT} />
        <p className="fs-10 text-primary fw-600 mb-0 ml-2 text-uppercase">
          {t(`classAttendance.Present`)}
        </p>
      </div>
      <div className="d-flex align-items-center mr-3">
        <StatusBox status={AttendanceStatus.ABSENT} />
        <p className="fs-10 text-primary fw-600 mb-0 ml-2 text-uppercase">
          {t(`classAttendance.Absent`)}
        </p>
      </div>
      <div className="d-flex align-items-center mr-3">
        <StatusBox status={AttendanceStatus.JUSTIFIED} />
        <p className="fs-10 text-primary fw-600 mb-0 ml-2 text-uppercase">
          {t(`classAttendance.Justified`)}
        </p>
      </div>
    </div>
  );
}
