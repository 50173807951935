import { TimeModule } from '../../types/TimeModule';
import { TIME_MODULES } from '../endpoints';
import request from '../request';

/**
 * Obtiene la lista de modulos de hora
 */
export const getTimeModules = () => {
  const url = TIME_MODULES.ALL;
  return request<TimeModule[]>(url, {
    params: {},
  });
};
