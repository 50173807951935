import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import GoBackButton from '../../../components/Button/GoBackButton';
import { PathsLayouts } from '../../../config/routes';
import CreateEvaluationGradeEntryBaseLoader from './CreateEvaluationGradeEntryBaseLoader';
import CreateEvaluationGradeEntryForm from './CreateEvaluationGradeEntryForm';
import CreateEvaluationGradeEntryInfoTags from './CreateEvaluationGradeEntryInfoTags';

export default function CreateEvaluationGradeEntry() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const backToListGradeList = useCallback(() => {
    history.push(`${PathsLayouts.gradeEntry}`);
  }, [history]);

  return (
    <CreateEvaluationGradeEntryBaseLoader sectionId={id}>
      {({ data, refresh }) => (
        <div className="m-3 py-4 px-3 bg-white rounded">
          <Row className="mb-4">
            <Col className="mb-4">
              <GoBackButton onClick={backToListGradeList} />
            </Col>
            <Col md={6}>
              <CreateEvaluationGradeEntryInfoTags data={data.sectionData} />
            </Col>
          </Row>
          <h2 className="text-primary fs-20 fw-700 mt-2">
            {t('gradeEntry.createEvaluation.title')}
          </h2>
          <p className="fs-16 mt-3 mb-4">
            {t('gradeEntry.createEvaluation.subtitle')}
          </p>
          <CreateEvaluationGradeEntryForm
            sectionId={id}
            gradesConfig={data.gradesConfig}
            refresh={refresh}
          />
        </div>
      )}
    </CreateEvaluationGradeEntryBaseLoader>
  );
}
