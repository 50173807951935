import { useMemo } from "react";
import { Table, useMobile } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import { StudentInfoCell, StatusToggler } from "./";
import NoStudentsMsg from "./NoStudentsMsg";

interface Props {
  rows: any[];
  isLoading?: boolean;
  onStatusChange?: (studentRow: any) => void;
  loadingStudentsIds: number[];
}

export default function AttendantsTable({
  rows = [],
  isLoading = false,
  onStatusChange = () => null,
  loadingStudentsIds = [],
}: Props) {
  const isMobile = useMobile();
  const { t } = useTranslation();

  const COLUMNS = useMemo(() => {
    if (isMobile) {
      return [
        {
          headerText: t(`classAttendance.studentFullNameTitle`),
          columnName: "fullName",
          cellFormat: (data: any) => {
            const { postulantPhotoId, fullName, status, observation } =
              data.row;
            return (
              <div className="d-flex flex-column">
                <StudentInfoCell
                  postulantPhotoId={postulantPhotoId}
                  fullName={fullName}
                />
                <div className="d-flex justify-content-center mt-3">
                  <StatusToggler
                    id={data.row.studentId}
                    value={status}
                    onChange={(status) => {
                      onStatusChange({ ...data.row, status });
                    }}
                    info={observation}
                    isLoading={loadingStudentsIds.includes(data.row.studentId)}
                  />
                </div>
              </div>
            );
          },
        },
      ];
    } else {
      return [
        {
          headerText: t(`classAttendance.studentFullNameTitle`),
          columnName: "fullName",
          cellFormat: (data: any) => {
            const { postulantPhotoId, fullName } = data.row;
            return (
              <StudentInfoCell
                postulantPhotoId={postulantPhotoId}
                fullName={fullName}
              />
            );
          },
        },
        {
          headerText: t(`classAttendance.attendanceStatusTitle`),
          columnName: "status",
          width: "430px",
          cellFormat: (data: any) => {
            const { status, observation } = data.row;
            return (
              <div className="d-flex justify-content-end">
                <StatusToggler
                  id={data.row.studentId}
                  value={status}
                  onChange={(status) => onStatusChange({ ...data.row, status })}
                  info={observation}
                  isLoading={loadingStudentsIds.includes(data.row.studentId)}
                />
              </div>
            );
          },
        },
      ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, onStatusChange, rows]);

  return (
    <div className="attendants-table-container">
      <Table
        striped={false}
        columns={COLUMNS}
        data={rows}
        noResultsText={<NoStudentsMsg />}
        isLoadingResults={isLoading}
      />
    </div>
  );
}
