import { Redirect, Route, Switch } from "react-router-dom";
import routes, { PathsLayouts } from "../../config/routes";

type RoutesByLayoutProps = {
  pathLayout: PathsLayouts;
};

/**
 * Es utilizado para renderizar todas las rutas asociadas a un layout
 */
const RoutesByLayout = ({
  pathLayout,
}: RoutesByLayoutProps): JSX.Element | null => {
  const routesByLayout = routes[pathLayout];

  return (
    <Switch>
      {routesByLayout.views.map(({ path, component }, key) => {
        return (
          <Route
            path={pathLayout + path}
            component={component}
            key={key}
            exact
          />
        );
      })}
      <Redirect from="*" to={pathLayout + routesByLayout.defaultPath} />
    </Switch>
  );
};

export default RoutesByLayout;
