import { useState, useEffect } from 'react';
import { getClassById } from '../api/requests/classes';
import { ClassResponseData } from '../types/Class';

export default function useClassData(id: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [classData, setClassData] = useState<ClassResponseData>();

  useEffect(() => {
    const requestClassData = async () => {
      setIsLoading(true);
      const res = await getClassById(id);

      setClassData(res.data || undefined);
      setIsLoading(false);
    };

    if (id) {
      requestClassData();
    }
  }, [id]);

  return {
    classData,
    isLoading,
  };
}
