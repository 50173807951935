import { SearchParamsPagination } from '../../types/Filters';
import { Campus, Period, School } from '../../types/Period';
import { PaginationRequestType } from '../../types/paginationRequestType';
import { GRADE_ENTRY } from '../endpoints';
import request from '../request';
import {
  PartialEvaluationSubevaluation,
  PartialEvaluationV2,
} from './partialEvaluation';

export interface TeacherSectionListOfGradeEntry {
  id: number;
  name: string;
  maximumStudent: number;
  createdAt: Date;
  updatedAt: Date;
  finalGrade: number | null;
  closedAt: string | null;
  course: {
    id: number;
    name: string;
    code: string;
    shortening: string;
    credits: number;
    createdAt: Date;
    updatedAt: Date;
    school?: School;
  };
  professors: [
    {
      id: number;
      createdAt: Date;
      updatedAt: Date;
    },
  ];
  period?: Period;
  campus?: Campus;
}

export interface TeachaerSectionCourseOfGradeEntry {
  Section_id: number;
  Section_name: string;
  Course_name: string;
  Course_shortening: string;
  Campus_name: string;
  Period_name: string;
}

export interface StudentsTestGrade {
  id?: number | null;
  studentId?: number;
  testGradeId?: number;
  grade?: string;
}

export interface TestGradeGroup {
  id?: number | null;
  name?: string;
  percentage?: number;
}

export interface StudentsFinalGrade {
  id: number | null;
  studentId: number;
  finalGradeId: number;
  grade: string;
}

export interface GradeEntry {
  finalGrade?: {
    id?: number | null;
    testGrade: number;
    finalTest: number;
  };
  testGrades: Array<TestGrades>;
}

export interface TestGrades {
  id?: number | null;
  name: string;
  date: string | Date | null;
  percentage: number;
  sectionId?: number;
  studentTestGrades: StudentsTestGrade[];
  group?: { id: number; name: string; percentage: number };
  groupId?: number;
}

export interface GradeEntryWithGroup extends GradeEntry {
  testGradesGroup: TestGradesGroup;
}

export type TestGradesGroup = PartialEvaluationSubevaluation[];

export type Grade = number | 'NR' | 'J';

export interface StudentTestGrade {
  studentTestGradesId?: number | null;
  studentTestGradesGrade?: string | null;
  testGradeId?: number;
  studentId?: number | null;
}

export interface StudentGradesMapResponse {
  id: number;
  fullName: string;
  run: string | null;
  passportNumber: string | null;
  countryId: string | null;
  testGrades: Record<
    number,
    {
      studentTestGradeId?: number | null;
      grade?: string | null;
      testGradeId: number;
    }
  >;
  partialGradeGroup: PartialEvaluationV2[];
  examGrade: {
    studentFinalGradeId: number;
    grade: Grade | null;
    finalGradeId: number;
  };
  partialGrade: Grade | null;
  finalGrade: Grade | null;
}

export interface StudentGradeEntry {
  Student_id: number;
  Account_name: string;
  Account_run: string;
  Account_paternalLastName: string;
  Account_maternalLastName: string;
  Account_paternalPreference: boolean;
  Account_passportNumber: string;
  Account_countryId: string;
  Student_fullName: string;
  TestGrade: StudentTestGrade[];
  FinalGrade: {
    finalGradeFinalTest: number;
    finalGradeId: number;
    studentFinalGradesGrade: string;
    studentFinalGradesId: number;
  };
}

export interface ConfigOfGradeEntry {
  key: string;
  value: number;
  description: string;
  createdAt: Date;
  updateAt: Date;
}

export const getTeacherSectionListOfGradeEntry = ({
  page = 0,
  itemsPerPage = 10,
  periodId,
  schoolId,
  campusId,
  searchSection,
  sectionStatus,
}: SearchParamsPagination) => {
  const url = `${GRADE_ENTRY.TEACHER_SECTION_LIST}`;
  const params = {
    items_per_page: itemsPerPage,
    page: page,
    periodId,
    schoolId,
    campusId,
    searchSection,
    sectionStatus,
  };

  return request<PaginationRequestType<TeacherSectionListOfGradeEntry>>(url, {
    method: 'get',
    params: params,
  });
};

export const getSectionCourseOfGradeEntry = (id: string) => {
  const url = `${GRADE_ENTRY.SECTION_COURSE}/${id}`;
  return request<TeachaerSectionCourseOfGradeEntry>(url, {
    method: 'get',
  });
};

export const getEvaluationGradeEntry = (id: number) => {
  const url = GRADE_ENTRY.BASE(id);
  return request<{ data: GradeEntryWithGroup }>(url, {
    method: 'get',
  });
};

export const getStudentsOfGradeEntry = (id: number) => {
  const url = GRADE_ENTRY.GET_STUDENT(id);
  return request<{ data: StudentGradesMapResponse[] }>(url, {
    method: 'get',
  });
};

export const getConfigOfGradeEntry = () => {
  const url = GRADE_ENTRY.CONFIG;
  return request<ConfigOfGradeEntry[]>(url, {
    method: 'get',
  });
};

export const detelePartialEvaluationOfGradeEntry = (id: number) => {
  const url = `${GRADE_ENTRY.DELETE_PARTIAL_EVALUATION}/${id}`;
  return request<any>(url, {
    method: 'delete',
  });
};

export const postStudentsTestGrade = (data: StudentsTestGrade[]) => {
  const url = `${GRADE_ENTRY.POST_STUDENT_TEST_GRADE}`;
  return request<any>(url, {
    method: 'post',
    data,
  });
};

export const postStudentsFinalGrade = (data: StudentsFinalGrade[]) => {
  const url = `${GRADE_ENTRY.POST_STUDENT_FINAL_GRADE}`;
  return request<any>(url, {
    method: 'post',
    data,
  });
};
