import { addToast } from '@octano/global-ui';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { searchClasses } from '../../../api/requests/classes';
import ClassSearch, {
  SearchParams,
  TimeType,
} from '../../../components/classSearch/ClassSearch';
import { useUserState } from '../../../hooks/useUserState';
import { ClassSearchResponse, ClassStatus } from '../../../types/Class';
import PastClassesTable from './PastClassesTable';

const RESULTS_PER_PAGE = 5;

export type SelectedClass = {
  id: ClassSearchResponse['id'];
  date: ClassSearchResponse['date'];
  professors: ClassSearchResponse['sectionSchedule']['section']['professors'];
  modules: string;
  schedule: string;
  activity: string;
  sectionClosedAt: Date | null;
};

interface Props {
  onClassRegistration: (classData: any) => void;
  onClassRecovery: (classData: any) => void;
  ref?: any;
}

function PastClassesSearch({
  onClassRegistration = () => {},
  onClassRecovery = () => {},
  ref,
}: Props) {
  const { t } = useTranslation();
  const { userData } = useUserState();

  const prevSearchParams = useRef<SearchParams>();
  const [results, setResults] = useState<SelectedClass[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoadingResults, setIsLoadingResults] = useState(false);

  /* Exponiendo la funcionalidad de repetir la busqueda */
  useImperativeHandle(ref, () => ({
    repeatSearch,
  }));

  const repeatSearch = () => {
    handleSearch(prevSearchParams.current);
  };

  const handleSearch = useCallback(
    async (searchParams: any) => {
      prevSearchParams.current = { ...searchParams };
      setIsLoadingResults(true);

      try {
        const res = await searchClasses({
          ...searchParams,
          status: ClassStatus.WAITING,
          itemsPerPage: RESULTS_PER_PAGE,
          account: userData?.id,
        });

        if (res.error) {
          addToast({
            icon: 'information',
            color: 'danger',
            text: t(`classesHistory.errors.search`),
          });
        } else {
          const classes = res.data?.data.map((classData: any) => {
            const { sectionSchedule, id, date } = classData;
            const {
              startModule,
              endModule,
              activity,
              section: { course, professors },
            } = sectionSchedule;
            const initTime = startModule.startTime.slice(0, 5);
            const endTime = endModule.endTime.slice(0, 5);

            return {
              id,
              date,
              professors,
              courseName: course.name,
              sectionId: searchParams.sectionId,
              modules: `${startModule.shortening} - ${endModule.shortening}`,
              schedule: `${initTime} - ${endTime}`,
              activity: activity.name,
              sectionClosedAt: sectionSchedule.section.closedAt,
            };
          });

          setResults(classes);
          setTotalPages(res.data?.total_pages);
          setTotalItems(res.data?.total_items);
        }
      } catch (err) {
        console.error(err);
      }
      setIsLoadingResults(false);
    },
    [t, userData?.id],
  );

  useEffect(() => {
    // Solo corre a partir de la 2da busqueda
    const sectionId = prevSearchParams.current?.sectionId;

    if (sectionId) {
      handleSearch({ ...prevSearchParams.current, page: currentPage });
    }
  }, [currentPage, handleSearch]);

  return (
    <>
      <h2 className="text-primary fs-20 fw-700 mb-3">
        {t(`classesHistory.pageTitle`)}
      </h2>
      <p className="text-light fs-14 mb-5">
        {t(`classesHistory.pageDescription`)}
      </p>
      <ClassSearch
        type={TimeType.PAST}
        handleSearch={(params: any) => {
          setCurrentPage(0);
          handleSearch(params);
        }}
      />
      {results && (
        <>
          <h2 className="text-primary fs-20 fw-700 mt-5 mb-4">
            {t(`classesHistory.classListTitle`)}
          </h2>
          <PastClassesTable
            results={results}
            resultsPerPage={RESULTS_PER_PAGE}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
            isLoadingResults={isLoadingResults}
            onClassRegistration={onClassRegistration}
            onClassRecovery={onClassRecovery}
          />
        </>
      )}
    </>
  );
}

export default forwardRef((props: Props, ref) =>
  PastClassesSearch({ ref, ...props }),
);
