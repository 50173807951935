import { useRef, useState } from "react";
import { addToast, Button, useMobile } from "@octano/global-ui";
import { createReportDownloadHandler } from "./handlerCreator";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { downloadFromBlob } from "../../../../utils/blob";
import NoResultsModal from "../NoResultsModal";
import GeneratingFileModal from "../GeneratingFileModal";
import SelectDatesModal from "../SelectDatesModal";

enum ModalType {
  NONE,
  DATE_RANGE_SELECTOR,
  GENERATING_FILE,
  NO_RESULTS,
}

type DownloadParams = {
  initDate: string;
  endDate: string;
};

interface Props {
  downloadUrl: string;
}

export default function ClassHistory({ downloadUrl }: Props) {
  const { t } = useTranslation();
  const isMobile = useMobile();

  const [openedModal, setOpenedModal] = useState<ModalType>(ModalType.NONE);
  const fileName = useRef("");

  const handleReportDownload = createReportDownloadHandler({
    beforeDownload: () => setOpenedModal(ModalType.GENERATING_FILE),
    afterDownload: ({ data, error }) => {
      if (data?.size === 0) {
        setOpenedModal(ModalType.NO_RESULTS);
      } else {
        setOpenedModal(ModalType.NONE);
      }
    },
    onSuccess: (data: Blob) => {
      if (data.size > 0) {
        const blob = new Blob([data], { type: "vnd.ms-excel" });
        downloadFromBlob(blob, fileName.current);

        addToast({
          icon: "success",
          color: "success",
          text: t(`reports.success.fileDownloaded`),
        });
      }
    },
    onError: () => {
      addToast({
        icon: "error",
        color: "danger",
        text: t(`reports.errors.errorGeneratingFile`),
      });
    },
  });

  const handleParamsSelection = (params: DownloadParams) => {
    const title = t(`reports.classHistory.fileNameTitle`);
    const connector = t(`reports.classHistory.datesStrConnector`);

    const formatedInit = dayjs(params.initDate).format("DD-MM-YYYY");
    const formatedEnd = dayjs(params.endDate).format("DD-MM-YYYY");

    fileName.current = `${title}_${formatedInit}_${connector}_${formatedEnd}.xlsx`;

    handleReportDownload(downloadUrl, params);
  };
  return (
    <>
      <NoResultsModal
        isOpen={openedModal === ModalType.NO_RESULTS}
        onConfirm={() => setOpenedModal(ModalType.NONE)}
        texts={{
          title: t("reports.classHistory.noResults.title"),
          description: t("reports.classHistory.noResults.description"),
        }}
      />
      <GeneratingFileModal isOpen={openedModal === ModalType.GENERATING_FILE} />
      <SelectDatesModal
        isOpen={openedModal === ModalType.DATE_RANGE_SELECTOR}
        onConfirm={handleParamsSelection}
        onCancel={() => setOpenedModal(ModalType.NONE)}
      />

      <div
        className="d-flex flex-wrap align-items-center justify-content-center"
        style={{ gap: 12 }}
      >
        <Button
          text={t(`common.btnDownload`)}
          icon="download"
          size="sm"
          outlined
          style={{ width: isMobile ? "90%" : 123 }}
          onClick={() => setOpenedModal(ModalType.DATE_RANGE_SELECTOR)}
        />
      </div>
    </>
  );
}
