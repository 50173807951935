import { Button, Table } from '@octano/global-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PartialEvaluationSubevaluations } from '../types';
import sumPercentage from '../utils/sumPercentage';
import TotalPercentageFooter from './TotalPercentageFooter';
import useSubevaluationGradeColumns from './useSubevaluationColumns';

export interface SubevaluationsTableProps {
  partialEvaluation: PartialEvaluationSubevaluations;
  partialEvaluationIndex: number;
  error?: boolean;
  onChange: (partialEvaluation: PartialEvaluationSubevaluations) => void;
}

export default function SubevaluationsTable(props: SubevaluationsTableProps) {
  const { partialEvaluation, partialEvaluationIndex, error, onChange } = props;
  const { t } = useTranslation();

  const addPartialSubevaluation = useCallback(() => {
    if (!partialEvaluation.grades) {
      return;
    }
    partialEvaluation.grades = [
      ...partialEvaluation.grades,
      { canItBeRemove: true },
    ];
    onChange(partialEvaluation);
  }, [onChange, partialEvaluation]);

  const columnsSubEvaluations = useSubevaluationGradeColumns({
    onChange,
    error,
    partialEvaluation,
    partialEvaluationIndex,
  });

  const percentage = sumPercentage(partialEvaluation.grades);
  const percentageValid = percentage === 100;
  return (
    <>
      <Table
        columns={columnsSubEvaluations}
        data={partialEvaluation.grades ?? []}
        striped={false}
        notResponsive
        noDefaultFoot
        footComponent={({ columns, data }) => (
          <TotalPercentageFooter
            dataLength={data.length}
            columnsLength={columns.length}
            error={error}
            percentageValid={percentageValid}
            percentage={percentage}
          />
        )}
      />
      {error && !percentageValid && (
        <p className="text-danger mt-2">
          {t('gradeEntry.createEvaluation.msgErrorTables.totalErrorOfTable')}
        </p>
      )}
      {error && partialEvaluation.grades.length < 2 && (
        <p className="text-danger mt-2">
          {t(
            'gradeEntry.createEvaluation.msgErrorTables.totalCountOfTableSubevaluations',
          )}
        </p>
      )}
      <div className="mt-2 text-right">
        <Button
          icon={'plus'}
          text={t('gradeEntry.partialEvaluationGradeEntry.addEvaluation')}
          outlined
          size="sm"
          onClick={addPartialSubevaluation}
        />
      </div>
      <hr />
    </>
  );
}
