export const isNumber = (str?: string | number | null): str is number => {
  if (typeof str === "number" && !isNaN(str)) {
    return true;
  }

  if (typeof str !== "string") {
    return false;
  }

  if (str.trim() === "") {
    return false;
  }

  return !Number.isNaN(Number(str));
};

export const gradeToFixed = (grade?: string | null, decimalsLength = 1) => {
  if (isNumber(grade)) {
    return Number(grade).toFixed(decimalsLength);
  }
  if (grade) return grade.toLocaleUpperCase();
  return "";
};
