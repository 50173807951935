import { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ClassSearch, {
  SearchParams,
  TimeType,
} from "../../../components/classSearch/ClassSearch";
import FutureClassesTable from "./FutureClassesTable";
import { addToast } from "@octano/global-ui";
import { searchClasses } from "../../../api/requests/classes";
import { ClassSearchResponse, ClassStatus } from "../../../types/Class";
import { useUserState } from "../../../hooks/useUserState";

const RESULTS_PER_PAGE = 5;

export type SelectedClass = {
  id: ClassSearchResponse["id"];
  date: ClassSearchResponse["date"];
  professors: ClassSearchResponse["sectionSchedule"]["section"]["professors"];
  modules: string;
  schedule: string;
  activity: string;
};

interface Props {
  onClassAdvance: (classData: SelectedClass) => void;
}

export default function FutureClassesSearch({
  onClassAdvance = () => {},
}: Props) {
  const { t } = useTranslation();
  const { userData } = useUserState();

  const prevSearchParams = useRef<SearchParams>();
  const [results, setResults] = useState<SelectedClass[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoadingResults, setIsLoadingResults] = useState(false);

  const handleSearch = useCallback(
    async (searchParams: SearchParams & { page?: number }) => {
      prevSearchParams.current = { ...searchParams };
      setIsLoadingResults(true);

      try {
        const res = await searchClasses({
          ...searchParams,
          status: ClassStatus.WAITING,
          itemsPerPage: RESULTS_PER_PAGE,
          account: userData?.id,
        });

        if (res.error) {
          addToast({
            icon: "information",
            color: "danger",
            text: t(`startClasses.errors.search`),
          });
        } else {
          const classes = res.data?.data.map(classData => {
            const { sectionSchedule, id, date } = classData;
            const {
              startModule,
              endModule,
              activity,
              section: { professors },
            } = sectionSchedule;
            const initTime = startModule.startTime.slice(0, 5);
            const endTime = endModule.endTime.slice(0, 5);

            return {
              id,
              date,
              professors,
              modules: `${startModule.shortening} - ${endModule.shortening}`,
              schedule: `${initTime} - ${endTime}`,
              activity: activity.name,
              sectionClosedAt: sectionSchedule.section.closedAt,
            };
          });

          setResults(classes);
          setTotalPages(res.data?.total_pages);
          setTotalItems(res.data?.total_items);
        }
      } catch (err) {
        console.error(err);
      }
      setIsLoadingResults(false);
    },
    [t, userData?.id]
  );

  useEffect(() => {
    // Solo corre a partir de la 2da busqueda
    const sectionId = prevSearchParams.current?.sectionId;

    if (sectionId) {
      handleSearch({
        ...(prevSearchParams.current as SearchParams),
        page: currentPage,
      });
    }
  }, [currentPage, handleSearch]);

  return (
    <>
      <h2 className='text-primary fs-20 fw-700 mt-5 mb-3'>
        {t(`startClasses.searchFutureClasses.title`)}
      </h2>
      <p className='text-light fs-14 mb-5'>
        {t(`startClasses.searchFutureClasses.description`)}
      </p>
      <ClassSearch
        type={TimeType.FUTURE}
        handleSearch={(params: SearchParams) => {
          setCurrentPage(0);
          handleSearch(params);
        }}
      />
      {results && (
        <>
          <h2 className='text-primary fs-20 fw-700 mt-5 mb-4'>
            {t(`startClasses.searchFutureClasses.classListTitle`)}
          </h2>
          <FutureClassesTable
            results={results}
            resultsPerPage={RESULTS_PER_PAGE}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={currentPage}
            onPageChange={page => setCurrentPage(page)}
            isLoadingResults={isLoadingResults}
            onClassAdvance={onClassAdvance}
          />
        </>
      )}
    </>
  );
}
