import dayjs from 'dayjs';

/**
 * Parsea la hora del server al formato HH:MM
 * @param dateLocalOffset Offset que viene de la respuesta del server timezone
 * @returns una hora en formato HH:MM
 */
export const formatServerTime = (dateLocalOffset: string): string => {
  const [hours, minutes] = dateLocalOffset
    .split('T')[1]
    .split('-')[0]
    .slice(0, 5)
    .split(':')
    .map((t) => Number(t));
  return `${hours}:${minutes}`;
};

/**
 * Calcula la diferencia en milisegundos entre un par de horas HH:MM.
 * @param time1
 * @param time2
 * @returns Si la diferencia es menor de 0 retorna 0, de lo contrario retorna la  diferencia
 */
export const getTimeDiff = (time1: string, time2: string): number => {
  const [hours1, minutes1] = time1.split(':').map((t) => Number(t));
  const date1 = dayjs().hour(hours1).minute(minutes1).second(0);

  const [hours2, minutes2] = time2.split(':').map((t) => Number(t));
  const date2 = dayjs().hour(hours2).minute(minutes2).second(0);

  const diff = date1.diff(date2);
  return diff > 0 ? diff : 0;
};
