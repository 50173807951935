import { addToast, Button } from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { createEvaluationOfGradeEntry } from '../requests';
import { GradesConfig, PartialEvaluation } from '../types';
import { isValidEvaluations } from '../utils/validEvaluation';
import EndCourseGradeEntry, { EndCourseGrade } from './EndCourseGradeEntry';
import PartialEvaluationGrade from './PartialEvaluationGradeEntry';

export interface CreateEvaluationGradeEntryFormProps {
  sectionId: number | string;
  gradesConfig: GradesConfig;
  refresh: () => void;
}

export default function CreateEvaluationGradeEntryForm({
  sectionId,
  gradesConfig,
  refresh,
}: CreateEvaluationGradeEntryFormProps) {
  const { t } = useTranslation();

  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const [handleError, setHandleError] = useState<{
    partialEvaluationGradeEntry: boolean;
    EndCourseGradeEntry?: string | null;
  }>({ partialEvaluationGradeEntry: false });

  const [gradeEntry, setGradeEntry] = useState<GradesConfig>(gradesConfig);

  const endCourseGrades = useMemo<EndCourseGrade[]>(() => {
    return [
      {
        name: t('gradeEntry.endCourseGradeEntry.partialNote'),
        percentage: `${gradeEntry.finalGrade.partialPercentage || 0}`,
      },
      {
        name: t('gradeEntry.endCourseGradeEntry.examNote'),
        percentage: `${gradeEntry.finalGrade.examPercentage || 0}`,
      },
      {
        name: t('gradeEntry.endCourseGradeEntry.finalNote'),
        percentage: '—%',
      },
    ];
  }, [gradeEntry, t]);

  const onChangeEndCourseGradeEntry = useCallback(
    (state: EndCourseGrade[]) => {
      let [testGrade, finalTest] = state;
      gradeEntry.finalGrade.examPercentage = Number(finalTest.percentage);
      gradeEntry.finalGrade.partialPercentage = Number(testGrade.percentage);
      setGradeEntry({ ...gradeEntry });
    },
    [setGradeEntry, gradeEntry],
  );

  const onChangePartialEvaluationGradeEntry = useCallback(
    (state: PartialEvaluation[]) => {
      gradeEntry.testGrades = [...state];
      setGradeEntry({ ...gradeEntry });
    },
    [setGradeEntry, gradeEntry],
  );

  const valiteIfFinalTestIsCorrect = useCallback(() => {
    let { examPercentage, partialPercentage } = gradeEntry.finalGrade;
    if (!examPercentage || !partialPercentage) {
      handleError.EndCourseGradeEntry = t(
        'gradeEntry.createEvaluation.msgErrorTables.saveAllTable',
      );
    } else if (examPercentage + partialPercentage !== 100) {
      handleError.EndCourseGradeEntry = t(
        'gradeEntry.createEvaluation.msgErrorTables.totalErrorOfTable',
      );
    } else {
      handleError.EndCourseGradeEntry = null;
    }
    setHandleError({ ...handleError });
    return handleError.EndCourseGradeEntry;
  }, [gradeEntry, handleError, t]);

  const valiteIfTestGradeIsCorrect = useCallback(() => {
    console.log('valiteIfTestGradeIsCorrect');
    const validEvaluations = isValidEvaluations(gradeEntry.testGrades);
    handleError.partialEvaluationGradeEntry = !validEvaluations;
    setHandleError({ ...handleError });
    return validEvaluations;
  }, [gradeEntry, handleError, setHandleError]);

  const handleValidate = useCallback(
    (execute: () => void) => {
      return () => {
        if (!valiteIfFinalTestIsCorrect() && valiteIfTestGradeIsCorrect()) {
          execute();
        }
      };
    },
    [valiteIfTestGradeIsCorrect, valiteIfFinalTestIsCorrect],
  );

  const onSubmitByCreateEvaluationGradeEntry = useCallback(async () => {
    setBtnLoading(true);
    console.log({ gradeEntry });
    let { error } = await createEvaluationOfGradeEntry(sectionId, gradeEntry);
    if (error) {
      addToast({
        icon: 'information',
        color: 'danger',
        text: t('gradeEntry.createEvaluation.msgErrorTables.errorSaveOfData'),
        style: { width: '600px' },
      });
    } else {
      addToast({
        icon: 'check',
        color: 'success',
        text: t('gradeEntry.createEvaluation.msgSuccess'),
        style: { width: '400px' },
      });
      refresh();
    }
    setBtnLoading(false);
  }, [sectionId, gradeEntry, t, refresh]);

  return (
    <>
      <Row className="mt-5 justify-content-between">
        <Col lg={3}>
          <EndCourseGradeEntry
            endCourseGrades={endCourseGrades}
            onChange={onChangeEndCourseGradeEntry}
            error={handleError.EndCourseGradeEntry}
            loading={false}
          />
        </Col>
        <Col lg={9} xl={7}>
          <PartialEvaluationGrade
            partialEvaluations={gradeEntry.testGrades}
            onChange={onChangePartialEvaluationGradeEntry}
            error={handleError.partialEvaluationGradeEntry}
          />
        </Col>
      </Row>
      <Row>
        <Col md={9} />
        <Col>
          <Button
            type="button"
            text={t('gradeEntry.createEvaluation.save')}
            fullwidth
            size="lg"
            loading={btnLoading}
            onClick={handleValidate(onSubmitByCreateEvaluationGradeEntry)}
          />
        </Col>
      </Row>
    </>
  );
}
