import { useTranslation } from "react-i18next";
import TableEmptyContent from "../../../components/Text/TableEmptyContent";

export interface AddEvaluationGradeEntryNotResultProps {
  search: {
    value: string | undefined;
    notResult: JSX.Element;
  };
  error: boolean;
}

export default function AddEvaluationGradeEntryNotResult({
  search,
  error,
}: AddEvaluationGradeEntryNotResultProps) {
  const { t } = useTranslation();

  if (search.value) {
    return search.notResult;
  }
  if (error) {
    return (
      <TableEmptyContent
        title={t("gradeEntry.addEvaluation.TableErrorContent.title")}
        subtitle={t("gradeEntry.addEvaluation.TableErrorContent.subtitle")}
        icon={"error"}
      />
    );
  }
  return (
    <TableEmptyContent
      title={t("gradeEntry.addEvaluation.tableEmptyContent.title")}
      subtitle={t("gradeEntry.addEvaluation.tableEmptyContent.subtitle")}
    />
  );
}
