import { UncontrolledTooltip } from "reactstrap";
import { Icon } from "@octano/global-ui";

interface Props {
  show: boolean;
  tooltipId: string;
  tooltipText: string;
}

export default function InfoWithTooltip({
  show,
  tooltipText = "",

  tooltipId,
}: Props) {
  if (!show) return null;

  return (
    <div
      id={`infoWithTooltip-${tooltipId}`}
      style={{ width: "fit-content", height: "fit-content" }}
    >
      <Icon name="information" size={14.71} />
      <UncontrolledTooltip
        placement="bottom"
        target={`infoWithTooltip-${tooltipId}`}
      >
        {tooltipText}
      </UncontrolledTooltip>
    </div>
  );
}
