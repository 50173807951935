export const SUBEVALUATION = 'subevaluation';
export const PARTIALGRADEGROUP = 'partialGradeGroup';

export const isColumnSubEvaluation = (columnName: string) => {
  return typeof columnName === 'string' && columnName.includes(SUBEVALUATION);
};

export const isColumnParcialSubEvaluation = (columnName: string) => {
  return (
    typeof columnName === 'string' && columnName.includes(PARTIALGRADEGROUP)
  );
};
