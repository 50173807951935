import { Col, Row } from "reactstrap";
import Loading from "../Info/Loading";

export interface InfoTagsProps {
  tags: Array<{
    label: string;
    value: string;
    sm?: number;
    xl?: number;
    md?: number;
    style?: object;
  }>;
  loading?: boolean;
  errorResults?: JSX.Element | JSX.Element[] | null;
}

export default function InfoTags(props: InfoTagsProps): JSX.Element {
  const { tags, loading, errorResults } = props;
  return (
    <>
      {
        <div className='outlined-box p-3'>
          {loading && !errorResults && <Loading insideCard />}
          {errorResults}
          <Row className='px-3 text-primary'>
            {tags.map(({ label, value, sm, xl, md, style }, key) => {
              return (
                <Col
                  key={key}
                  {...{ sm, xl, md, style }}
                  className='text-ellipsis'
                  title={value}>
                  <span className='fs-14 fw-600 text-uppercase'>{label}</span>
                  <br />
                  <span className='fs-14'>{value}</span>
                </Col>
              );
            })}
          </Row>
        </div>
      }
    </>
  );
}
