import { Icon } from "@octano/global-ui";
import { useTranslation } from "react-i18next";

export default function NoStudentsMsg() {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div style={{ maxWidth: 346, marginTop: 70, marginBottom: 65 }}>
        <Icon name="information" color="primary" size="61px" />
        <p className="text-medium fw-700 mt-4 mb-3 fs-18">
          {t(`classAttendance.noStudentsMessage`)}
        </p>
      </div>
    </div>
  );
}
