import request from "../request";
import { POSTULANTS } from "../endpoints";

/**
 * Obtiene el url de la foto subida por el postulante
 * @param postulantId Id del postulante
 * @returns
 */
export function getPostulantPhoto(postulantId: number) {
  const url = `${POSTULANTS.BASE}/${postulantId}/photo`;
  return request<string>(url);
}
