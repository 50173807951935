import { useEffect, useState } from "react";
import { getPostulantPhoto } from "../../../api/requests/postulants";

export default function StudentInfoCell({
  fullName = "",
  postulantPhotoId = -1,
}: {
  fullName: string;
  postulantPhotoId: number;
}) {
  const [pictureUrl, setPictureUrl] = useState("");

  useEffect(() => {
    const fetchPictureUrl = async () => {
      const res = await getPostulantPhoto(postulantPhotoId);

      if (res.error) {
        setPictureUrl("");
      } else {
        setPictureUrl(res.data);
      }
    };

    if (postulantPhotoId && !pictureUrl) fetchPictureUrl();
  }, [postulantPhotoId, pictureUrl]);

  return (
    <div className="d-flex align-items-center">
      <div className="attendance-student-picture mr-4 overflow-hidden">
        {pictureUrl && <img src={pictureUrl} alt="" className="rounded" />}
      </div>
      <p className="mb-0 text-dark fs-16">{fullName}</p>
    </div>
  );
}
