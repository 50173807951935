import { Button, SingleCardContainer, TextInput } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useValidations } from "../../../hooks/useValidations";
import campusLogo from "../../../assets/svg/logo_campus_color.svg";

const DEFAULT_VALUES = {
  newPassword: "",
  repeatNewPassword: "",
};

export type FormValues = typeof DEFAULT_VALUES;

interface Props {
  userEmail: string;
  onSubmit?: (values: FormValues) => void;
  isLoading?: boolean;
  isNew?: boolean;
}

export default function ChangePasswordForm({
  userEmail = "",
  onSubmit = () => null,
  isLoading = false,
  isNew = false,
}: Props) {
  const { t } = useTranslation();
  const {
    msgValidations,
    validateTextNotEmpty,
    validatePassword,
    validateRepeatPassword,
  } = useValidations();
  const { handleSubmit, control, watch } = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  const passwordValue = watch("newPassword");

  const getTitles = () => {
    const prefix = "auth";
    const keyType = isNew ? "setFirstPassword" : "changePassword";

    return {
      title: t(`${prefix}.${keyType}Title`),
      description: t(`${prefix}.${keyType}Description`),
      btnText: t(`${prefix}.${keyType}BtnText`),
    };
  };

  const titles = getTitles();

  return (
    <SingleCardContainer
      brandLogoColor={<img src={campusLogo} alt="global-3000-campus-logo" />}
    >
      <form className="px-4 py-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-center">
          <img src="/tenant/logo.svg" alt="institution logo" />
        </div>
        <h1 className="text-dark font-weight-bold fs-20 text-center my-3 ">
          {titles.title}
        </h1>
        <p className="fs-18 text-center mb-4">
          {`${userEmail} ${titles.description}`}
        </p>
        <div className="mb-3">
          <TextInput
            type="password"
            label={t(`auth.labelPassword`)}
            name="newPassword"
            autocomplete="new-password"
            control={control}
            tooltip={msgValidations.invalidPassword}
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
                password: validatePassword,
              },
            }}
          />
        </div>
        <div className="mb-3">
          <TextInput
            type="password"
            label={t(`auth.labelRepeatPassword`)}
            name="repeatNewPassword"
            autocomplete="new-password"
            control={control}
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
                password: validateRepeatPassword(passwordValue),
              },
            }}
          />
        </div>
        <div className="mt-5 mb-2">
          <Button
            type="submit"
            text={titles.btnText}
            className="w-100"
            loading={isLoading}
          />
        </div>
      </form>
    </SingleCardContainer>
  );
}
