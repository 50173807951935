import { Dispatch } from "react";

export interface AuthResponse {
  account: Account;
  permissions: Permission[];
  access: Access;
  refresh: Refresh;
}

export enum PermissionName {
  SHOW_GRADE_ENTRY = 'show.teacher-sections-list',
  SHOW_SECTION_COURSE = "show.section-course",
  CREATE_SECTION_GRADES = 'create.section-grades'
}

export interface Permission {
  id: number;
  name_public: string;
  name: PermissionName;
}

export interface Access {
  token: string;
}

export interface Refresh {}

interface Account {
  id: number;
  email: string;
  fullname: string;
  isActive: boolean;
  studentId?: number | null;
  teacherId?: number | null;
}

export interface UserData extends Account {
  permissions?: Permission["name"][];
}

export type UserState = {
  isLogged: boolean;
  isSessionExpired: boolean;
  userData?: UserData;
  hasCheckedToken: boolean;
};

export enum UserActionTypes {
  RESET_STATE = "RESET_STATE",
  SET_IS_LOGGED = "SET_IS_LOGGED",
  SET_IS_SESSION_EXPIRED = "SET_IS_SESSION_EXPIRED",
  SET_FULL_NAME = "SET_FULL_NAME",
  SET_USER_DATA = "SET_USER_DATA",
}

export type UserAction =
  | { type: UserActionTypes.RESET_STATE }
  | { type: UserActionTypes.SET_IS_LOGGED; payload: boolean }
  | { type: UserActionTypes.SET_IS_SESSION_EXPIRED; payload: boolean }
  | { type: UserActionTypes.SET_FULL_NAME; payload: string }
  | {
      type: UserActionTypes.SET_USER_DATA;
      payload: Partial<UserState["userData"]> | UserState["userData"];
    };

export type UserContextType = {
  state: UserState;
  dispatch: Dispatch<UserAction>;
};

export enum UserSessionEvent {
  EXPIRED = "user-session-expired",
}
