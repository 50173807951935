import request from '../request';
import { CLASSES } from '../endpoints';
import { PaginationRequestType } from '../../types/paginationRequestType';
import {
  ClassResponseData,
  ClassSearchResponse,
  ClassStatus,
  RecoveredClassResponse,
  UntrackedClass,
} from '../../types/Class';

/**
 * Obtiene las n clases mas cercanas (de ese mismo dia)
 */
export function getNearestClasses(accountId: number, itemsPerPage?: number) {
  const url = CLASSES.BASE;
  const params = {
    today: 1,
    items_per_page: itemsPerPage ?? 2,
    page: 0,
    account: accountId,
  };
  return request<PaginationRequestType<ClassResponseData>>(url, { params });
}

/**
 * Actualiza el estado de una clase al nuevo estado especificado
 * @param id: id de clase, stataus: nuevo status, teacher: Objeto teacher
 * @returns
 */
export function updateClassStatus({
  id,
  status,
}: {
  id: number;
  status: string;
}) {
  const url = CLASSES.PUT_CLASS_STATUS(id);
  const method = 'PUT';
  const data = { status };

  return request<ClassResponseData>(url, { method, data });
}

interface PaginatedSearch<T> extends PaginationRequestType {
  data: T[];
  total_items: number;
}
/**
 * Realiza una busqueda de clases, limitada por fechas y por el status
 * @returns una lista de clases paginada
 */
export type ClassSearchParams = {
  sectionId: number;
  page?: number;
  itemsPerPage?: number;
  initialDate?: string;
  endDate?: string;
  status?: ClassStatus;
  account?: number;
};

export function searchClasses({
  sectionId,
  page = 0,
  itemsPerPage = 5,
  initialDate,
  endDate,
  status,
  account,
}: ClassSearchParams) {
  const url = CLASSES.SEARCH;
  const params = {
    sectionId,
    page,
    items_per_page: itemsPerPage,
    dateInit: initialDate,
    dateEnd: endDate,
    status,
    account,
  };
  return request<PaginatedSearch<ClassSearchResponse>>(url, { params });
}

/**
 * Genera una nueva clase en la fecha especificada y a partir de los datos de una clase perdida,
 * @returns La nueva clase
 */
export function reScheduleClass({
  dateRecovery,
  startModuleId,
  endModuleId,
  lessonsId,
  sectionId,
}: {
  dateRecovery: string;
  startModuleId: number;
  endModuleId: number;
  lessonsId: number;
  sectionId: number;
}) {
  const url = CLASSES.RECOVER;
  const method = 'POST';
  const data = {
    dateRecovery,
    startModuleId,
    endModuleId,
    lessonsId,
    sectionId,
  };
  return request<RecoveredClassResponse>(url, { method, data });
}

/**
 * Obtiene una clase con el id especificado
 * @param id
 * @returns La clase con el id especificado
 */
export function getClassById(id: number) {
  const url = CLASSES.GET_CLASS_STATUS(id);
  return request<ClassResponseData>(url);
}

/**
 * Retorna el listado de clases iniciadas pero sin asistencia registrada
 * @param teacherId
 */
export function getUntrackedClasses(teacherId: number | string) {
  const url = CLASSES.GET_UNTRACKED_CLASSES(teacherId);
  return request<UntrackedClass[]>(url);
}
