import { Button, SingleCardContainer, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import campusLogo from '../../../assets/svg/logo_campus_color.svg';

interface Props {
  isNew?: boolean;
}

export default function PasswordChangedMsg({ isNew = false }: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const goToLogin = () => history.push('/auth/login');

  const titleKey = isNew ? 'setFirstPassword' : 'changedPassword';

  return (
    <SingleCardContainer
      brandLogoColor={<img src={campusLogo} alt="global-3000-campus-logo" />}
    >
      <div className="px-4 py-2 d-flex align-items-center flex-column">
        <div className="d-flex justify-content-center">
          <img src="/tenant/logo.svg" alt="institution logo" />
        </div>

        <div className="mt-4 mb-3">
          <Icon name="success" size={45} />
        </div>

        <h1 className="text-dark text-center fs-18 fw-600 mx-5">
          {t(`auth.${titleKey}SuccessfullyTitle`)}
        </h1>
        <p className="text-center fs-16 mt-1">
          {t(`auth.changedPasswordSuccessfullyDescription`)}
        </p>
        <Button
          text={t(`auth.changedPasswordSuccessfullyBtnText`)}
          className="w-100 mt-4"
          onClick={goToLogin}
        />
      </div>
    </SingleCardContainer>
  );
}
