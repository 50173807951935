const RadioCell = ({
  data,
  text = '',
  selected = false,
  onRadioSelection = () => {},
}: {
  data: any;
  text: string;
  selected: boolean;
  onRadioSelection: (data: any) => void;
}) => {
  return (
    <div className="d-flex align-items-center">
      <input
        className="radio-button-table"
        type="radio"
        onChange={() => onRadioSelection(data)}
        checked={selected}
      />
      <label className="mb-0 ml-3">{text}</label>
    </div>
  );
};

export default RadioCell;
