export enum ClassType {
  COURSE = "course",
  ASSISTANTSHIP = "assistantship",
  LAB = "lab",
  FIELD = "field",
  WORKSHOP = "workshop",
  PRACTICE = "practice",
  THESIS = "thesis",
  OTHER = "other",
}

export enum ClassStatus {
  INIT = "Init",
  WAITING = "Waiting",
  CANCELLED = "Cancelled",
  CLOSED = "Closed",
}

export interface Class {
  id: number;
  name: string;
  section: string;
  type: ClassType;
  typeLabel: string;
  status: ClassStatus;
  classroom: string;
  date: string;
  day: string;
  modules: string;
  schedule: string;
  teacher: Teacher;
  responsibles: string[];
  professors: string[];
  accountId: number;
  sectionClosedAt: Date | null;
}

export enum AttendanceStatus {
  PRESENT = "Present",
  ABSENT = "Absent",
  JUSTIFIED = "Justified",
}

const activities = [
  "Catedra",
  "Laboratorio",
  "Terreno",
  "Taller",
  "Other",
  "Tésis",
  "Ayudantía",
  "Práctica",
] as const;
export type ActivityName = typeof activities[number];

export interface UntrackedClass {
  id: number;
  date: string;
  dateInit: string;
  moduleNameStart: string;
  moduleNameEnd: string;
  moduleShortStart: `M${number}`;
  moduleShortEnd: `M${number}`;
  moduleHourStart: string;
  moduleHourEnd: string;
  activity: ActivityName;
  section: string;
  course: string;
  courseCode: string;
  sectionClosedAt: Date | null;
}

/////////////////////////// ONLY THE RESPONSES /////////////////////////////
export interface StartModule {
  id: number;
  name: string;
  shortening: string;
  startTime: string;
  endTime: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface EndModule {
  id: number;
  name: string;
  shortening: string;
  startTime: string;
  endTime: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Activity {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Account {
  id: number;
  email: string;
  personalEmail?: any;
  name: string;
  run: string;
  paternalLastName: string;
  maternalLastName: string;
  paternalPreference: boolean;
  birthday: string;
  lastLogin: string;
  isActive: boolean;
  passportNumber?: any;
  countryId?: any;
  studentId?: any;
  assistantId: number;
  teacherId: number;
  fullName: string;
}
export interface Responsible {
  id: number;
  accountId: number;
  sectionScheduleId: number;
  typeOfResponsible: string;
  account: Account;
}

export interface Professor {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  account: Account;
}

export interface Course {
  id: number;
  name: string;
  code: string;
  shortening: string;
  credits: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface Section {
  id: number;
  name: string;
  maximumStudent: number;
  createdAt: Date;
  updatedAt: Date;
  professors: Professor[];
  course: Course;
  closedAt: Date | null;
}

export interface Classroom {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface SectionSchedule {
  id: number;
  day: string;
  createdAt: Date;
  updatedAt: Date;
  startModule: StartModule;
  endModule: EndModule;
  activity: Activity;
  section: Section;
  classroom: Classroom;
  responsibles: Responsible[];
}

export interface Teacher {
  id: number;
}

export interface ClassResponseData {
  id: number;
  date: string;
  status: ClassStatus;
  dateInitLessons?: any;
  createdAt: Date;
  updatedAt: Date;
  sectionSchedule: SectionSchedule;
  teacher: Teacher;
  account: Account;
}

export interface ClassListResponse {
  data: ClassResponseData[];
  total: number;
  total_pages: number;
}

export interface RecoveredClassResponse {
  data: {
    id: number;
    date: string;
    status: ClassStatus;
    statusRow: string;
    dateRecovery: string;
    parent: {
      id: number;
    };
    sectionSchedule: {
      id: number;
    };
    teacherRow: {
      id: number;
    };
    dateInitLessons: null;
    createdAt: string;
    updatedAt: string;
  };
}

export interface Student {
  id: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface Module {
  id: number;
  name: string;
  shortening: string;
  startTime: string;
  endTime: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ClassAttendance {
  id: number;
  status: string;
  observation: string;
  createdAt: Date;
  updatedAt: Date;
  lesson: ClassResponseData;
  student: Student;
  module: Module;
}

export interface ClassAttendanceArrayObject {
  data: ClassAttendance[];
  total: number;
  total_pages: number;
}

export interface Account {
  id: number;
  email: string;
  name: string;
  run: string;
  paternalLastName: string;
  maternalLastName: string;
  paternalPreference: boolean;
  birthday: string;
  isActive: boolean;
  fullName: string;
}

export interface Photo {
  id: number;
  extension: string;
  postulantId: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface Postulant {
  id: number;
  rut: string;
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  paternalPreference: boolean;
  fullName: string;
  email: string;
  birthday: string;
  sexId: number;
  maritalStatusId: number;
  createdAt: Date;
  updatedAt: Date;
  photo?: Photo;
}

export interface Student {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  account?: Account;
  postulant?: Postulant;
}

export interface Data {
  id: number;
  name: string;
  maximumStudent: number;
  createdAt: Date;
  updatedAt: Date;
  students?: Student[];
}

export interface SectionStudents {
  data: Data;
}

export interface AttendantRecord {
  id?: number;
  status: string;
  lessonId: number;
  studentId: number;
  moduleId: number;
  observation?: string;
}

// Search response
export interface ClassSearchResponse {
  id: number;
  date: string;
  status: string;
  statusRow?: any;
  dateInitLessons?: any;
  dateClosedLessons?: any;
  createdAt: string;
  updatedAt: string;
  dateRecovery?: any;
  sectionSchedule: SectionSchedule;
  teacher?: Teacher;
}
