import { useState, useEffect } from 'react';
import { getStudentsList } from '../api/requests/sections';
import { Student } from '../types/Class';

export default function useSectionStudentsList(sectionId: number) {
  const [isLoading, setIsLoading] = useState(false);
  const [studentsList, setStudentsList] = useState<Student[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchStudents = async () => {
      setIsLoading(true);
      setError(false);

      const res = await getStudentsList(sectionId);

      if (res.error && res.error.error.response?.status !== 404) {
        setError(true);
      }

      setStudentsList(res?.data?.data?.students || []);
      setIsLoading(false);
    };

    if (sectionId) {
      fetchStudents();
    }
  }, [sectionId]);

  return {
    isLoading,
    studentsList,
    error,
  };
}
