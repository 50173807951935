import { Button, Modal, Icon } from "@octano/global-ui";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  descriptionType: "gradesEntry" | "attendanceTracking";
}

export default function SectionClosedModal({
  isOpen,
  onConfirm,
  descriptionType,
}: Props) {
  const { t } = useTranslation();
  const prefix = "sectionClosedModal";

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column align-items-center text-center">
        <Icon name="warning" size={45} />
        <h1 className="text-dark fs-20 mt-3 mb-4">{t(`${prefix}.title`)}</h1>
        <p className="fs-16 mb-5">
          {t(`${prefix}.descriptions.${descriptionType}`)}
        </p>
        <Button
          text={t(`common.btnGotIt`)}
          onClick={() => onConfirm()}
          fullwidth
          style={{ maxWidth: 284 }}
        ></Button>
      </div>
    </Modal>
  );
}
