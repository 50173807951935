import { useCallback, useEffect, useState } from "react";
import {
  ConfigOfGradeEntry,
  getConfigOfGradeEntry,
} from "../../../api/requests/gradeEntry";

export default function useValidatorNotes() {
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [errorLoading, setErrorLoading] = useState<boolean>(false);
  const [config, setConfig] = useState<{
    max: number;
    min: number;
    decimalsLength: number;
    pass: number;
  }>({
    max: 0,
    min: 0,
    decimalsLength: 0,
    pass: 0,
  });

  const requestData = useCallback(async () => {
    let { data, error } = await getConfigOfGradeEntry();
    if (error) {
      setErrorLoading(true);
    } else if (data) {
      setErrorLoading(false);
      const max = data.find(
        config => config.key === "grades-entry.maximum-grade"
      ) as ConfigOfGradeEntry;
      const min = data.find(
        config => config.key === "grades-entry.minimum-grade"
      ) as ConfigOfGradeEntry;
      const decimalsLength = data.find(
        config => config.key === "grades-entry.number-of-decimals-to-show"
      ) as ConfigOfGradeEntry;
      const pass = data.find(
        config => config.key === "grades-entry.passing-grade"
      ) as ConfigOfGradeEntry;

      setConfig({
        max: max.value,
        min: min.value,
        decimalsLength: decimalsLength.value,
        pass: pass.value,
      });
    }
    setIsLoad(true);
  }, []);

  const formatterNumber = useCallback(
    (value: string) => {
      const { max, min, decimalsLength } = config;
      if (
        Number(value) >= min &&
        Number(value) <= max &&
        value.length <= decimalsLength + 2
      ) {
        return `${value}`;
      }
      return "";
    },
    [config]
  );

  const formattertNote = useCallback(
    (value: string) => {
      if (/[0-9]|[0-9].[0-9]/.test(value)) {
        return formatterNumber(value);
      } else if (
        value.toLocaleLowerCase() === "j" ||
        value.toLocaleLowerCase() === "n" ||
        value.toLocaleLowerCase() === "nr"
      ) {
        return value;
      }
      return "";
    },
    [formatterNumber]
  );

  useEffect(() => {
    requestData();
  }, [requestData]);
  return {
    config,
    isLoad,
    formattertNote,
    errorLoading,
  };
}
