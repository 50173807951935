import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getSectionTeacherPortalRequest } from '../../api/requests/teacherPortal';
import { PeriodsLoader } from '../../components/LoaderContexts/PeriodsLoader';
import { SearchControls } from '../../components/SearchControls/SearchControls';
import { SearchParams } from '../../types/Filters';
import TableSubject, { TableSubjectProps } from './parts/TableSubject';

interface HandleSearch {
  searchParams?: SearchParams;
  page: number;
}

export default function PeriodEnd() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  const [sectionsClosed, setSectionsClosed] = useState<
    TableSubjectProps['data']
  >([]);
  const [sectionsNotClosed, setSectionsNotClosed] = useState<
    TableSubjectProps['data']
  >([]);

  const [filters, setFilters] = useState<SearchParams>({});

  const mainhandleSearch = useCallback(
    async ({ searchParams, page = 0 }: HandleSearch) => {
      setLoading(true);
      const { data, error } = await getSectionTeacherPortalRequest({
        page,
        itemsPerPage: Number.MAX_SAFE_INTEGER,
        periodId: searchParams?.periodId,
        schoolId: searchParams?.schoolId,
        campusId: searchParams?.campusId,
        searchSection: searchParams?.searchSection,
        sectionStatus: searchParams?.sectionStatus,
      });

      if (error) {
        setError(error.code);
      } else if (data) {
        setError(undefined);
        const { sectionsClosed, sectionsNotClosed } = data;
        setSectionsClosed(
          sectionsClosed.map((section) => ({
            periodName: section.period.name,
            schoolName: section.course?.school?.name,
            campusName: section.campus.name,
            id: section.id,
            acronym: section.course.shortening,
            name: section.course.name,
            section: section.name,
            percentageApproved: `${
              section.closedWith?.passPercentage
                ? Math.round(section.closedWith.passPercentage)
                : 0
            }%`,
          })),
        );
        setSectionsNotClosed(
          sectionsNotClosed.map((section) => ({
            periodName: section.period.name,
            schoolName: section.course?.school?.name,
            campusName: section.campus.name,
            id: section.id,
            acronym: section.course.shortening,
            name: section.course.name,
            section: section.name,
          })),
        );
      }
      setLoading(false);
    },
    [setLoading, setSectionsClosed, setSectionsNotClosed, setError],
  );

  const handleClear = async () => {
    setSectionsClosed([]);
    setSectionsNotClosed([]);
    setFilters({});
    mainhandleSearch({
      searchParams: {},
      page: 0,
    });
  };

  useEffect(() => {
    mainhandleSearch({
      searchParams: { ...filters },
      page: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainhandleSearch]);

  return (
    <PeriodsLoader>
      {({ data }) => (
        <div className="m-3 py-4 px-3 bg-white rounded">
          <p className="fs-16 mt-3 mb-4">{t('periodEnd.subtitle')}</p>

          <SearchControls
            periods={data.periods}
            onSearch={mainhandleSearch}
            onClear={handleClear}
            setFilters={setFilters}
          />

          <TableSubject
            type="toClose"
            loading={loading}
            data={sectionsNotClosed}
            error={error}
          />

          <TableSubject
            type="closed"
            loading={loading}
            data={sectionsClosed}
            error={error}
          />
        </div>
      )}
    </PeriodsLoader>
  );
}
