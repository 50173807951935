import { ToastProvider, Dialog as DialogGlobal } from "@octano/global-ui";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dialog from "./components/Modals/Dialog";
import AppVersion from "./components/version/AppVersion";
import routes from "./config/routes";
import UserProvider from "./providers/UserProvider";

function App() {
  return (
    <ToastProvider>
      <UserProvider>
        <AppVersion />
        <Router>
          <Switch>
            {Object.entries(routes)?.map(([key, value]) => {
              const Layout = value.layoutComponent;
              return (
                <Route
                  key={key}
                  path={key}
                  render={(props: any) => <Layout {...props} />}
                />
              );
            })}
            <Redirect from='*' to='/auth' />
          </Switch>
        </Router>
        <Dialog />
        <DialogGlobal />
      </UserProvider>
    </ToastProvider>
  );
}

export default App;
