import { Loading } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { updateClassStatus } from '../../api/requests/classes';
import useNearestClasses from '../../hooks/useNearestClasses';
import useServerSettings from '../../hooks/useServerSettings';
import { useUserState } from '../../hooks/useUserState';
import { Class, ClassStatus } from '../../types/Class';
import showModalsectionClosedAt from '../../utils/showModalSectionClosedAt';
import { ClassCard, ConfirmStartDialog, EmptyStateMsg } from './parts';
import FutureClassesSearch from './parts/FutureClassesSearch';

export default function StartClasses() {
  const history = useHistory();
  const { t } = useTranslation();
  const { userData } = useUserState();

  const [showConfirmStartDialog, setShowConfirmStartDialog] = useState(false);
  const [classToStart, setClassToStart] = useState<Class | undefined>();
  const { nearestClasses, isLoading } = useNearestClasses({
    accountId: userData?.id,
  });
  const { offset } = useServerSettings();
  const today = new Date();

  const startClass = useCallback(async () => {
    if (!userData?.id) {
      return;
    }
    if (classToStart) {
      const { id } = classToStart;

      try {
        await updateClassStatus({
          status: ClassStatus.INIT,
          id,
        });
        history.push(`/classes/${id}/attendance`);
      } catch (err) {
        console.error(err);
      }
    }
  }, [classToStart, history, userData]);

  const onClassAdvance = useCallback(
    (classData: any) => {
      if (classData.sectionClosedAt) {
        showModalsectionClosedAt(t);
      } else {
        setClassToStart(classData);
      }
    },
    [t],
  );

  useEffect(() => {
    async function handleStart() {
      if (!classToStart) return;

      try {
        const [classStartTime] = classToStart?.schedule.split(' - ');
        const [hours, minutes] = classStartTime
          .split(':')
          .map((n) => Number(n));

        const classDateTime = dayjs(classToStart.date)
          .utc()
          .utcOffset(offset)
          .hour(hours)
          .minute(minutes);
        const currentDateTime = dayjs().utc().utcOffset(offset).second(0);

        if (currentDateTime.isBefore(classDateTime)) {
          setShowConfirmStartDialog(true);
        } else {
          startClass();
        }
      } catch (err) {
        console.log(err);
      }
    }

    handleStart();
  }, [classToStart, startClass, offset]);

  const renderNearestClassList = () => {
    if (isLoading) {
      return (
        <div className="text-center loader">
          <Loading textBody={t('common.loading.body')} />
        </div>
      );
    }

    if (nearestClasses.length === 0) {
      return (
        <EmptyStateMsg
          msg={t(`startClasses.noScheduledClassesForToday`)}
          date={`Fecha: ${today
            .toLocaleDateString(undefined, {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            })
            .replaceAll('/', '-')} Hora: ${today.toLocaleTimeString()}`}
        />
      );
    }

    return (
      <>
        <p>{t(`startClasses.pageDescription`)}</p>
        <div className="d-flex px-3 flex-wrap">
          {nearestClasses.map((c: Class) => (
            <ClassCard
              key={c.id}
              classData={c}
              onStartClick={() => setClassToStart(c)}
              onModifyClick={() => history.push(`/classes/${c.id}/attendance`)}
            />
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      <ConfirmStartDialog
        isOpen={showConfirmStartDialog}
        onCancel={() => setShowConfirmStartDialog(false)}
        onConfirm={() => startClass()}
        onDialogClose={() => setClassToStart(undefined)}
      />
      <div className="m-3 py-4 px-3 bg-white rounded">
        <div className="p-4 rounded todays-classes-section">
          <h2 className="text-primary fs-20 fw-700">
            {t(`startClasses.todaysClasses`)}
          </h2>
          {renderNearestClassList()}
        </div>

        <FutureClassesSearch onClassAdvance={onClassAdvance} />
      </div>
    </>
  );
}
