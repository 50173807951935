import { useCallback, useEffect, useState } from "react";
import { Button, ColumnTable, Icon, Table } from "@octano/global-ui";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ClassTypeLegend from "../../../components/ClassTypeLegend/ClassTypeLegend";
import { UntrackedClass } from "../../../types/Class";
import { useUserState } from "../../../hooks/useUserState";
import { getUntrackedClasses } from "../../../api/requests/classes";
import dayjs from "dayjs";
import showModalsectionClosedAt from "../../../utils/showModalSectionClosedAt";

export default function UntrackedInitiatedClassesTable() {
  const { t } = useTranslation();
  const history = useHistory();
  const { userData } = useUserState();

  const [classes, setClasses] = useState<UntrackedClass[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClasses = async () => {
      const { data, error } = await getUntrackedClasses(userData?.id || 0);

      if (error) {
        console.log(error.data.messsage);
      }

      const orderedData = data?.sort((a, b) =>
        a.courseCode > b.courseCode ? 1 : -1
      );
      setClasses(orderedData || []);
    };

    if (userData?.id) {
      fetchClasses().finally(() => setLoading(false));
    }
  }, [userData?.id]);

  const formatModuleTime = (moduleTime: string) => {
    const [hours, minutes] = moduleTime.split(":").map(t => Number(t));
    return dayjs().hour(hours).minute(minutes).format("hh:mm");
  };

  const formatDate = (dateStr: string) => {
    return dayjs(dateStr).utc().format("DD/MM/YYYY");
  };

  const verifyIfSectionIsClosed = useCallback(
    (section: UntrackedClass, value: any) => {
      if (section.sectionClosedAt) {
        showModalsectionClosedAt(t);
      } else {
        history.push(`/classes/${value}/attendance`);
      }
    },
    [history, t]
  );

  const TABLE_COLUMNS: ColumnTable<UntrackedClass>[] = [
    {
      columnName: "courseCode",
      headerText: t(`common.terms.shortening`),
    },
    {
      columnName: "section",
      headerText: t(`common.terms.section`),
    },
    {
      columnName: "course",
      headerText: t(`common.terms.name`),
      tdClassName: "flex-grow-1",
    },
    {
      columnName: "date",
      headerText: t(`common.terms.date`),
      cellFormat: ({ value }) => formatDate(value),
    },
    {
      columnName: "dateInit",
      headerText: t(`common.terms.initDate`),
      cellFormat: ({ value }) => formatDate(value),
    },
    {
      columnName: "modules",
      headerText: t(`common.terms.modules`),
      cellFormat: ({ row }) =>
        `${row.moduleShortStart} - ${row.moduleShortEnd}`,
    },
    {
      columnName: "schedule",
      headerText: t(`common.terms.schedule`),
      cellFormat: ({ row }) => {
        return `${formatModuleTime(row.moduleHourStart)} - ${formatModuleTime(
          row.moduleHourEnd
        )}`;
      },
    },
    {
      columnName: "activity",
      headerText: t(`common.terms.activity`),
      cellFormat: ({ value }) => <ClassTypeLegend activityName={value} />,
    },
    {
      columnName: "id",
      headerText: t(`common.terms.action`),
      cellFormat: ({ value, row }) => (
        <Button
          text={t(`common.btnRegister`)}
          size='sm'
          outlined
          onClick={() => verifyIfSectionIsClosed(row, value)}
        />
      ),
      width: "160px",
    },
  ];

  if (loading) {
    return (
      <div
        className='d-flex justify-content-center align-items-center'
        style={{ height: 300 }}>
        <Icon className='spin' name='circle' color='secondary' size='40px' />
      </div>
    );
  }

  return (
    <div className='text-center'>
      {classes.length > 0 ? (
        <Table data={classes} columns={TABLE_COLUMNS} />
      ) : (
        <div
          className='d-flex flex-column align-items-center justify-content-center'
          style={{ height: 300 }}>
          <Icon name='success' size={55} />
          <h3 className='text-medium text-center fs-20 fw-400 mt-4'>
            {t(`untrackedAttendance.noUntrackedClassesMsg`)}
          </h3>
        </div>
      )}
    </div>
  );
}
